import React from 'react';

const Demo = () => {
  return (
    <div>
      <h1>Демо</h1>
    </div>
  );
};

export default Demo;
