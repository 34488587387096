import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Container,
    Grid,
    Typography,
    Box,
    Button,
    Skeleton,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    CircularProgress,
    Alert,
    alpha
} from '@mui/material';
import { AuthContext } from '../App';
import {
    fetchLessonDetails,
    fetchCourseDetails,
    fetchLessonAssignments,
    submitAssignment,
    completeLesson,
    deployStudentEnvironment
} from '../services/apiService';
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    MenuBook as MenuBookIcon,
    CheckCircle as CheckCircleIcon,
    RadioButtonUnchecked as RadioButtonUncheckedIcon,
    PlayCircle as PlayCircleIcon,
    AccessTime as AccessTimeIcon,
    EmojiEvents as EmojiEventsIcon
} from '@mui/icons-material';
import TextContent from '../components/courses/TextContent';
import VideoContent from '../components/courses/VideoContent';
import ImageContent from '../components/courses/ImageContent';
import AssignmentList from '../components/courses/AssignmentList';
import AssignmentSubmissionDialog from '../components/courses/AssignmentSubmissionDialog';

const LessonPage = () => {
    const { courseId, lessonId } = useParams();
    const [lesson, setLesson] = useState(null);
    const [courseDetails, setCourseDetails] = useState(null);
    const [assignments, setAssignments] = useState([]);
    const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deployingEnvironment, setDeployingEnvironment] = useState(false);
    const navigate = useNavigate();
    const { isAuthenticated, token } = useContext(AuthContext);
    const [deployingEnvironments, setDeployingEnvironments] = useState({});

    const deployEnvironments = useCallback(async (assignments) => {
        for (const assignment of assignments) {
            if (!deployingEnvironments[assignment.id]) {
                setDeployingEnvironments(prev => ({
                    ...prev,
                    [assignment.id]: true
                }));

                try {
                    await deployStudentEnvironment(token, {
                        template_id: assignment.sandbox_details.template_id,
                        assignment_id: assignment.id
                    });
                } catch (error) {
                    console.error(`Error deploying environment for assignment ${assignment.id}:`, error);
                } finally {
                    setDeployingEnvironments(prev => ({
                        ...prev,
                        [assignment.id]: false
                    }));
                }
            }
        }
    }, [deployingEnvironments, token]);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const [lessonData, courseData, assignmentsData] = await Promise.all([
                    fetchLessonDetails(token, courseId, lessonId),
                    fetchCourseDetails(courseId),
                    fetchLessonAssignments(token, lessonId)
                ]);

                setLesson(lessonData);
                setCourseDetails(courseData);
                setAssignments(assignmentsData);

                // Автоматически разворачиваем окружения для sandbox-заданий
                const sandboxAssignments = assignmentsData.filter(a =>
                    a.assignment_type === 'sandbox' &&
                    a.sandbox_details &&
                    !a.sandbox_environment
                );

                if (sandboxAssignments.length > 0) {
                    deployEnvironments(sandboxAssignments);
                }

                setError(null);
            } catch (error) {
                console.error("Error loading lesson data:", error);
                setError("Не удалось загрузить данные урока");
            } finally {
                setLoading(false);
            }
        };

        if (isAuthenticated && token) {
            loadData();
        } else {
            navigate('/login', { state: { from: `/courses/${courseId}/lessons/${lessonId}` } });
        }
    }, [courseId, lessonId, isAuthenticated, token, navigate, deployEnvironments]);


    const handleSubmitAssignment = async (assignmentId, submissionData) => {
        try {
            await submitAssignment(token, assignmentId, submissionData);
            setSubmissionDialogOpen(false);
            setSelectedAssignment(null);
            // Обновляем список заданий после отправки
            const assignmentsData = await fetchLessonAssignments(token, lessonId);
            setAssignments(assignmentsData);
        } catch (error) {
            console.error('Error submitting assignment:', error);
            setError('Не удалось отправить решение');
        }
    };

    const handleOpenSandbox = async (assignment) => {
        if (!assignment.sandbox_details) {
            setError('Для этого задания не настроена песочница');
            return;
        }

        // Проверяем существующее окружение
        if (assignment.sandbox_environment?.status === 'active') {
            const sandboxUrl = `/sandbox/${assignment.sandbox_environment.sandbox}?assignment_id=${assignment.id}&mode=assignment`;
            window.open(sandboxUrl, '_blank');
            return;
        }

        // Если окружение не готово, разворачиваем
        setDeployingEnvironment(true);
        try {
            const environment = await deployStudentEnvironment(token, {
                template_id: assignment.sandbox_details.template_id,
                assignment_id: assignment.id
            });

            const sandboxUrl = `/sandbox/${environment.sandbox_instance}?assignment_id=${assignment.id}&mode=assignment`;
            window.open(sandboxUrl, '_blank');
        } catch (error) {
            console.error('Error deploying environment:', error);
            setError('Не удалось развернуть окружение');
        } finally {
            setDeployingEnvironment(false);
        }
    };

    const handleCompleteLessonClick = async () => {
        try {
            await completeLesson(token, lessonId);
            // После успешного завершения урока переходим к следующему
            const currentLessonIndex = courseDetails.modules
                .flatMap(module => module.lessons)
                .findIndex(l => l.id === parseInt(lessonId));
            const allLessons = courseDetails.modules.flatMap(module => module.lessons);
            const nextLesson = allLessons[currentLessonIndex + 1];

            if (nextLesson) {
                navigate(`/courses/${courseId}/lessons/${nextLesson.id}`);
            } else {
                navigate(`/courses/${courseId}`);
            }
        } catch (error) {
            console.error('Error completing lesson:', error);
            setError('Не удалось завершить урок');
        }
    };

    const renderContent = (content) => {
        switch (content.type) {
            case 'text':
                return <TextContent content={content.data} />;
            case 'video':
                return <VideoContent url={content.data} />;
            case 'image':
                return <ImageContent url={content.data} alt={content.alt || 'Lesson image'} />;
            default:
                return <Typography>Неподдерживаемый тип контента</Typography>;
        }
    };

    const navigateToLesson = (newLessonId) => {
        navigate(`/courses/${courseId}/lessons/${newLessonId}`);
    };

    const renderProgressIcon = (lessonItem) => {
        if (lessonItem.id === parseInt(lessonId)) {
            return <PlayCircleIcon sx={{ fontSize: 20, color: 'primary.main' }} />;
        }
        return lessonItem.completed ?
            <CheckCircleIcon sx={{ fontSize: 20, color: 'success.main' }} /> :
            <RadioButtonUncheckedIcon sx={{ fontSize: 20, color: 'grey.400' }} />;
    };

    if (!isAuthenticated) {
        return null;
    }

    if (loading) {
        return (
            <Container maxWidth="xl" sx={{ py: 4 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <Skeleton variant="rectangular" height={400} />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Skeleton variant="rectangular" height={200} />
                        <Box className="mt-4">
                            <Skeleton variant="text" width="60%" />
                            <Skeleton variant="text" width="40%" />
                            <Skeleton variant="text" width="80%" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="xl" sx={{ py: 4 }}>
                <Alert severity="error">{error}</Alert>
            </Container>
        );
    }

    const currentLessonIndex = courseDetails.modules
        .flatMap(module => module.lessons)
        .findIndex(l => l.id === parseInt(lessonId));
    const allLessons = courseDetails.modules.flatMap(module => module.lessons);
    const previousLesson = allLessons[currentLessonIndex - 1];
    const nextLesson = allLessons[currentLessonIndex + 1];



    return (
        <Container maxWidth="xl" sx={{ py: 4 }}>
            <Box sx={{ display: 'flex', gap: 4 }}>
                {/* Боковая панель */}
                <Box sx={{
                    width: { xs: '100%', lg: '25%' },
                    display: { xs: 'none', lg: 'block' }
                }}>
                    <Box sx={{
                        bgcolor: alpha('#d2d1d1', 0.05),
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: alpha('#7a7877', 0.1),
                        p: 3,
                        position: 'sticky',
                        top: 24
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
                            <MenuBookIcon sx={{ color: 'black' }} />
                            <Typography variant="h7" sx={{
                                fontWeight: 500,
                                color: 'black'
                            }}>
                                Содержание курса
                            </Typography>
                        </Box>

                        <List sx={{ '& .MuiListItem-root': { px: 0 } }}>
                            {courseDetails?.modules.map((module) => (
                                <Box key={module.id} sx={{ mb: 2 }}>
                                    <ListItem>
                                        <ListItemText
                                            primary={module.title}
                                            primaryTypographyProps={{
                                                color: 'text.secondary',
                                                fontWeight: 500
                                            }}
                                        />
                                    </ListItem>
                                    <List sx={{ pl: 2 }}>
                                        {module.lessons.map((lessonItem) => (
                                            <ListItemButton
                                                key={lessonItem.id}
                                                selected={lessonItem.id === parseInt(lessonId)}
                                                onClick={() => navigateToLesson(lessonItem.id)}
                                                sx={{
                                                    borderRadius: 1,
                                                    mb: 0.5,
                                                    '&.Mui-selected': {
                                                        bgcolor: alpha('#7a7877', 0.1),
                                                        color: 'warning.main',
                                                        '&:hover': {
                                                            bgcolor: alpha('#7a7877', 0.15),
                                                        }
                                                    },
                                                    '&:hover': {
                                                        bgcolor: alpha('#7a7877', 0.05),
                                                    }
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    {renderProgressIcon(lessonItem)}
                                                    <Typography variant="body2" noWrap>
                                                        {lessonItem.title}
                                                    </Typography>
                                                </Box>
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Box>
                            ))}
                        </List>
                    </Box>
                </Box>

                {/* Основной контент */}
                <Box sx={{ flex: 1 }}>
                    {/* Шапка урока */}
                    <Box sx={{
                        bgcolor: alpha('#d2d1d1', 0.05),
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: alpha('#7a7877', 0.1),
                        p: 4,
                        mb: 3,
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                            boxShadow: `0 4px 20px ${alpha('#7a7877', 0.1)}`
                        }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            mb: 3
                        }}>
                            <Box>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    color: 'warning.main',
                                    mb: 1
                                }}>
                                    <AccessTimeIcon sx={{ fontSize: 20 }} />
                                    <Typography variant="body2">
                                        Время изучения: {lesson?.duration || '30'} минут
                                    </Typography>
                                </Box>
                                <Typography variant="h4" sx={{
                                    fontWeight: 'bold',
                                    color: 'text.primary' // Вернули черный цвет текста
                                }}>
                                    {lesson?.title}
                                </Typography>
                            </Box>
                            {lesson?.points && (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    bgcolor: alpha('#d2d1d1', 0.1),
                                    color: 'warning.main',
                                    px: 2,
                                    py: 1,
                                    borderRadius: 2,
                                    border: '1px solid',
                                    borderColor: alpha('#7a7877', 0.2)
                                }}>
                                    <EmojiEventsIcon />
                                    <Typography sx={{ fontWeight: 500 }}>
                                        {lesson.points} баллов
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        {/* Контент урока */}
                        <Box sx={{
                            '& > *': { mb: 3 }
                        }}>
                            {lesson?.content?.map((content, index) => (
                                <Box key={index}>
                                    {renderContent(content)}
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    {/* Задания */}
                    {assignments?.length > 0 && (
                        <Box sx={{ mb: 3 }}>
                            <AssignmentList
                                assignments={assignments}
                                token={token}
                                onSubmit={(assignment) => {
                                    setSelectedAssignment(assignment);
                                    setSubmissionDialogOpen(true);
                                }}
                                onOpenSandbox={handleOpenSandbox}
                                deployingEnvironments={deployingEnvironments}
                            />
                        </Box>
                    )}

                    {/* Навигация */}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        bgcolor: alpha('#d2d1d1', 0.05),
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: alpha('#7a7877', 0.1),
                        p: 3
                    }}>
                        <Button
                            variant="outlined"
                            disabled={!previousLesson}
                            onClick={() => previousLesson && navigateToLesson(previousLesson.id)}
                            startIcon={<ChevronLeftIcon />}
                            sx={{
                                borderColor: 'warning.main',
                                color: 'warning.main',
                                '&:hover': {
                                    borderColor: 'warning.dark',
                                    bgcolor: alpha('#d2d1d1', 0.05)
                                }
                            }}
                        >
                            Предыдущий урок
                        </Button>

                        <Button
                            variant="contained"
                            onClick={handleCompleteLessonClick}
                            disabled={deployingEnvironment}
                            sx={{
                                bgcolor: 'warning.main',
                                '&:hover': {
                                    bgcolor: 'warning.dark'
                                }
                            }}
                        >
                            {deployingEnvironment ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Завершить урок'
                            )}
                        </Button>

                        <Button
                            variant="outlined"
                            disabled={!nextLesson}
                            onClick={() => nextLesson && navigateToLesson(nextLesson.id)}
                            endIcon={<ChevronRightIcon />}
                            sx={{
                                borderColor: 'warning.main',
                                color: 'warning.main',
                                '&:hover': {
                                    borderColor: 'warning.dark',
                                    bgcolor: alpha('#d2d1d1', 0.05)
                                }
                            }}
                        >
                            Следующий урок
                        </Button>
                    </Box>
                </Box>
            </Box>

            {/* Диалог отправки задания остается без изменений */}
            {selectedAssignment && (
                <AssignmentSubmissionDialog
                    open={submissionDialogOpen}
                    onClose={() => {
                        setSubmissionDialogOpen(false);
                        setSelectedAssignment(null);
                    }}
                    assignment={selectedAssignment}
                    onSubmit={handleSubmitAssignment}
                />
            )}
        </Container>
    );
};

export default LessonPage;