import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import RegisterForm from './RegisterForm';

const MainContent = ({ isAuthenticated }) => {
  const [registerOpen, setRegisterOpen] = useState(false);

  const handleOpenRegister = () => {
    setRegisterOpen(true);
  };

  const handleCloseRegister = () => {
    setRegisterOpen(false);
  };

  if (isAuthenticated) {
    return null;
  }

  return (
    <div className="container mx-auto pt-10 text-center max-w-screen-lg mt-24"> {/* Добавлен отступ сверху */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h1
          className="text-3xl md:text-5xl font-heading font-bold text-primary mb-4"
          style={{
            background: 'linear-gradient(90deg, #FF6F00, #FF8F00)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          Здесь можно
        </h1>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <p className="text-lg md:text-xl text-textPrimary mb-8 md:mb-16">
          удалить базу данных, сломать сервер и вообще всё трогать собственными руками чтобы наконец разобраться в технологиях
        </p>
      </motion.div>

      <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-6 mb-16 md:mb-24">
        <button
          className="bg-gradient-to-r from-orange-500 to-orange-600 text-white py-3 px-6 md:py-4 md:px-8 rounded-lg hover:from-orange-600 hover:to-orange-700 transition duration-300 transform hover:scale-105 shadow-lg"
          onClick={handleOpenRegister}
        >
          Попробовать бесплатно
        </button>
        <button className="border border-primary text-primary py-3 px-6 md:py-4 md:px-8 rounded-lg hover:border-[#E65100] hover:text-[#E65100] transition duration-300 transform hover:scale-105 shadow-lg">
          Демо
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12 mb-16 md:mb-24">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="bg-beige p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl hover:scale-105 transition duration-300"
          style={{ borderRadius: '16px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}
        >
          <h3 className="text-xl md:text-2xl font-heading text-primary mb-4 md:mb-6">
            Для изучающих ИТ технологии
          </h3>
          <p className="text-textPrimary">
            Проходи курсы с индивидуальными заданиями, отрабатывай навыки в песочнице, которая создаст учебную среду под тебя.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="bg-beige p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl hover:scale-105 transition duration-300"
          style={{ borderRadius: '16px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}
        >
          <h3 className="text-xl md:text-2xl font-heading text-primary mb-4 md:mb-6">
            Для наставников и учебных центров
          </h3>
          <p className="text-textPrimary">
            Генерируй персонализированные практические задания, создавай курсы, автоматизируй проверку и фидбек.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="bg-beige p-6 md:p-8 rounded-lg shadow-lg hover:shadow-xl hover:scale-105 transition duration-300"
          style={{ borderRadius: '16px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}
        >
          <h3 className="text-xl md:text-2xl font-heading text-primary mb-4 md:mb-6">
            Для профессионалов
          </h3>
          <p className="text-textPrimary">
            AI создаст архитектуру по твоему запросу, а дополнительные возможности настройки песочницы помогут протестировать новые технологии.
          </p>
        </motion.div>
      </div>

      {/* Модальное окно для регистрации */}
      <Dialog open={registerOpen} onClose={handleCloseRegister}>
        <DialogContent>
          <RegisterForm onRegisterSuccess={handleCloseRegister} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MainContent;
