import React from 'react';
import { TextField, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ onSearch, value, onChange }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onSearch(value);
    };

    return (
        <form onSubmit={handleSubmit} style={{ width: '50%', margin: '0 auto' }}>
            <TextField
                fullWidth
                variant="standard"
                placeholder="Найти курсы"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    bgcolor: 'orange.500',
                                    '&:hover': { bgcolor: 'orange.600' },
                                    minWidth: '40px',
                                    height: '30px'
                                }}
                            >
                                <SearchIcon fontSize="small" />
                            </Button>
                        </InputAdornment>
                    ),
                    style: { fontSize: '0.9rem' }
                }}
            />
        </form>
    );
};

export default SearchBar;