import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { TextField, Button } from '@mui/material';
import { startCamundaProcess, updateSandboxName } from '../../services/apiService';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import GenerationLoader from '../GenerationLoader';
import html2canvas from 'html2canvas';

const SandboxHeader = ({ token, userInfo, sandboxName, showRecreateButton, sandboxId, stageRef }) => {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(sandboxName || '');
  const [prompt, setPrompt] = useState('');
  const [ProcessId, setProcessId] = useState(null);
  const [SandboxId, setSandboxId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartGeneration = async () => {
    try {
      // Запуск процесса
      const data = await startCamundaProcess(token, prompt, userInfo.id, true, sandboxId);

      setProcessId(data.process_id);
      setSandboxId(data.sandbox_id);
      setIsLoading(true);  // Устанавливаем флаг загрузки
    } catch (error) {
    }

    setOpen(false);
  };

  const handleNameEdit = () => {
    setIsEditing(true);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNameSave = async () => {
    try {
      await updateSandboxName(token, sandboxId, name);
      setIsEditing(false);
    } catch (error) {
      console.error('Ошибка при обновлении имени песочницы:', error);
    }
  };

  // Функция для скачивания схемы
  const handleDownloadDiagram = async () => {
    if (stageRef && stageRef.current) {
      try {
        let dataURL;

        // Попытка использовать Konva Stage
        if (typeof stageRef.current.getStage === 'function') {
          const stage = stageRef.current.getStage();
          dataURL = stage.toDataURL({
            pixelRatio: 2,
            mimeType: 'image/png'
          });
        }
        // Если это не Konva Stage, используем html2canvas
        else {
          const canvas = await html2canvas(stageRef.current, {
            scale: 2,
            useCORS: true,
            logging: false
          });
          dataURL = canvas.toDataURL('image/png');
        }

        // Создаем ссылку для скачивания
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = `${name}_diagram.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Ошибка при создании изображения:', error);
        // Здесь можно добавить уведомление для пользователя об ошибке
      }
    } else {
      console.error('stageRef не определен или не имеет свойства current');
      // Здесь можно добавить уведомление для пользователя об ошибке
    }
  };

  // Если идет процесс генерации, показываем лоадер
  if (isLoading && ProcessId && SandboxId) {
    return <GenerationLoader token={token} ProcessId={ProcessId} SandboxId={SandboxId} />;
  }

  return (
      <div className="bg-gray-100 flex justify-between items-start w-full py-2 px-4 shadow-md">
        <div className="flex items-center">
          {isEditing ? (
              <div className="flex items-center">
                <TextField
                    value={name}
                    onChange={handleNameChange}
                    variant="outlined"
                    size="small"
                    sx={{ marginRight: '8px' }}
                />
                <Button variant="contained" color="primary" onClick={handleNameSave}>
                  Сохранить
                </Button>
              </div>
          ) : (
              <h2 className="text-xl">{name}</h2>
          )}
          <IconButton onClick={handleNameEdit} size="small" sx={{ marginLeft: '8px' }}>
            <EditIcon fontSize="small" />
          </IconButton>
        </div>

        {/* Ссылка для скачивания схемы */}
        <div className="flex items-center space-x-4">
          <Button
              variant="text"
              color="primary"
              onClick={handleDownloadDiagram}
              sx={{ textTransform: 'none', fontSize: '0.9rem' }}
          >
            Скачать схему
          </Button>

          {showRecreateButton && (
              <button
                  className="bg-orange-500 text-white py-2 px-2 rounded hover:bg-orange-600"
                  onClick={handleClickOpen}
              >
                Пересоздать
              </button>
          )}
        </div>

        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <h2 className="text-lg font-bold mb-4">Введите промпт для пересоздания системы</h2>
            <TextField
                multiline
                rows={5}
                fullWidth
                variant="outlined"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Например: 'система по учёту ёжиков в лесу' или 'система с микросервисной архитектурой и брокером сообщений для взаимодействия с CRM' "
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleStartGeneration}
                className="mt-4"
                style={{ marginTop: '20px' }}
            >
              Начать генерацию
            </Button>
          </DialogContent>
        </Dialog>
      </div>
  );
};

export default SandboxHeader;
