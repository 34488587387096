import axios from 'axios';
import config from '../config';
import i18n from '../i18n';

// Конфигурация Axios с базовым URL
const api = axios.create({
  baseURL: config.apiUrl,
});

// Аутентификация и пользователи
export const login = async (username, password) => {
  const response = await api.post('/users/login', {
    username,
    password,
  });
  return response.data;
};

export const register = async (username, email, password) => {
  const response = await api.post('/users/register', {
    username,
    email,
    password,
  });
  return response.data;
};

export const getUserInfo = async (token) => {
  const response = await api.get('/users/user-info', {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return response.data;
};

export const fetchUserProfiles = async (token, userIds) => {
  try {
    const queryString = userIds.map(id => `id=${id}`).join('&');
    const response = await api.get(`/users/profile?${queryString}`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user profiles:", error);
    throw error;
  }
};

export const add_tester = async (username, email, fullAdditionalInfo) => {
  const response = await api.post('/users/add-tester/', {
    name: username,
    email: email,
    add_information: fullAdditionalInfo,
  });
  return response.data;
};

// Курсы
export const fetchCourses = async (params = {}) => {
  try {
    let url = '/courses/courses?';
    if (params.search) url += `search=${params.search}&`;
    if (params.category) url += `category=${params.category}&`;
    if (params.minPrice) url += `min_price=${params.minPrice}&`;
    if (params.maxPrice) url += `max_price=${params.maxPrice}&`;
    if (params.ordering) url += `ordering=${params.ordering}&`;
    if (params.page) url += `page=${params.page}&`;
    if (params.pageSize) url += `page_size=${params.pageSize}&`;

    const response = await api.get(url);
    return {
      results: response.data.results || [],
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous
    };
  } catch (error) {
    console.error("Error fetching courses:", error);
    return { results: [], count: 0, next: null, previous: null };
  }
};

export const fetchCourseDetails = async (courseId) => {
  try {
    const response = await api.get(`/courses/courses/${courseId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching course details:", error);
    throw error;
  }
};

export const fetchCourseCategories = async () => {
  try {
    const response = await api.get('/courses/categories');
    return response.data;
  } catch (error) {
    console.error("Error fetching course categories:", error);
    return [];
  }
};

// Зачисления
export const enrollInCourse = async (token, courseId) => {
  try {
    const response = await api.post(`/courses/courses/${courseId}/enroll`,
        {},
        { headers: { Authorization: `Token ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error enrolling in course:", error);
    throw error;
  }
};

export const fetchEnrollments = async (token) => {
  try {
    const response = await api.get('/courses/enrollments/list', {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching enrollments:", error);
    throw error;
  }
};

// Уроки и задания
export const fetchLessonDetails = async (token, courseId, lessonId) => {
  try {
    const response = await api.get(`/courses/lessons/${lessonId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching lesson details:", error);
    throw error;
  }
};

export const fetchLessonAssignments = async (token, lessonId) => {
  try {
    const response = await api.get(`/courses/lessons/${lessonId}/assignments`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching lesson assignments:", error);
    return [];
  }
};

export const getAssignmentDetails = async (token, assignmentId) => {
  try {
    const response = await api.get(`/courses/assignments/${assignmentId}`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching assignment details:", error);
    throw error;
  }
};

export const getAssignmentStatus = async (token, assignmentId) => {
  try {
    const response = await api.get(`/courses/assignments/${assignmentId}/status`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching assignment status:", error);
    return null;
  }
};

export const submitAssignment = async (token, assignmentId, data) => {
  try {
    const response = await api.post('/courses/submissions', {
      assignment: assignmentId,
      ...data
    }, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting assignment:", error);
    throw error;
  }
};

export const completeLesson = async (token, lessonId) => {
  try {
    const response = await api.post(`/courses/lessons/${lessonId}/complete`, {}, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error completing lesson:", error);
    throw error;
  }
};

// Окружения для заданий
export const createEnvironmentTemplate = async (token, data) => {
  try {
    const response = await api.post('/courses/environment-templates/create_from_sandbox/', data, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error creating environment template:", error);
    throw error;
  }
};

export const deployStudentEnvironment = async (token, data) => {
  try {
    const response = await api.post('/courses/student-environments/deploy/', data, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error deploying student environment:", error);
    throw error;
  }
};

export const getStudentEnvironmentStatus = async (token, environmentId) => {
  try {
    const response = await api.get(`/courses/student-environments/${environmentId}/`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error("Error getting environment status:", error);
    throw error;
  }
};

// Рейтинги и отзывы
export const fetchCourseReviews = async (courseId) => {
  try {
    const response = await api.get(`/courses/reviews?course_id=${courseId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching course reviews:", error);
    return [];
  }
};

export const fetchRecommendedCourses = async (token) => {
  try {
    const response = await api.get('/courses/recommendations', {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching recommended courses:", error);
    return [];
  }
};

// Песочница
export const startCamundaProcess = async (token, prompt, userId, is_reassembly, sandbox) => {
  try {
    const currentLanguage = i18n.language;
    const sandbox_id = parseInt(sandbox, 10);
    const response = await api.post(
        '/sandbox/start-process',
        {
          prompt,
          user_id: userId,
          language: currentLanguage,
          is_reassembly,
          sandbox: sandbox_id
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error starting Camunda process:", error);
    throw error;
  }
};

export const getAssistantResponse = async (token, data) => {
  const response = await api.post('/sandbox/assistant-response', data, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return response.data;
};

export const updateSandboxName = async (token, sandboxId, newName) => {
  try {
    const response = await api.patch(
        `/sandbox/sandbox/${sandboxId}/`,
        { name: newName },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating sandbox name:", error);
    throw error;
  }
};

export const fetchSandboxes = async (token, userId) => {
  try {
    const response = await api.get(`/sandbox/sandbox/?owner=${userId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching sandboxes:", error);
    return [];
  }
};

export const fetchInstances = async (token, sandboxId) => {
  try {
    const response = await api.get(`/sandbox/instance/?sandbox=${sandboxId}&archive=False`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching instances:", error);
    return [];
  }
};

export const getProcessStage = async (token, ProcessId) => {
  try {
    const response = await api.get(`/sandbox/process/?id=${ProcessId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching process stage:", error);
    return null;
  }
};

export const fetchSandboxComponents = async (token, sandboxId) => {
  try {
    const response = await api.get(`/sandbox/component/?archive=False&sandbox=${sandboxId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching sandbox components:", error);
    return [];
  }
};

export const fetchSandboxComponentsRelations = async (token, sandboxId, componentIds) => {
  try {
    const promises = componentIds.map(id =>
        api.get(`/sandbox/relation/?sandbox=${sandboxId}&from_component=${id}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
    );
    const responses = await Promise.all(promises);
    return responses.flatMap(response => response.data);
  } catch (error) {
    console.error("Error fetching sandbox relations:", error);
    return [];
  }
};

export const executeSQLQuery = async (token, componentId, query) => {
  try {
    const response = await api.post(
        `/sandbox/component/${componentId}/execute-sql/`,
        { query },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error executing SQL query:", error);
    throw error;
  }
};

export const getAssignmentFeedback = async (token, assignmentId) => {
  try {
    const response = await api.get(`/courses/assignments/${assignmentId}/feedback/`, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error getting assignment feedback:', error);
    throw error;
  }
};