import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Button,
    Paper,
    Alert,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import { CheckCircle as CheckCircleIcon, AlertCircle as ErrorIcon } from 'lucide-react';
import { getAssignmentFeedback } from '../../services/apiService';

const AssignmentFeedback = ({ assignment_id, token, onRetry }) => {
    const [feedback, setFeedback] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFeedback = async () => {
            try {
                const response = await getAssignmentFeedback(token, assignment_id);
                setFeedback(response);
                setError(null);
            } catch (error) {
                setError('Не удалось загрузить результаты проверки');
                console.error('Error fetching feedback:', error);
            } finally {
                setLoading(false);
            }
        };

        if (assignment_id && token) {
            const interval = setInterval(fetchFeedback, 10000);
            fetchFeedback();
            return () => clearInterval(interval);
        }
    }, [assignment_id, token]);

    if (loading) {
        return (
            <Box className="flex justify-center p-4">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" className="mb-4">
                {error}
            </Alert>
        );
    }

    if (!feedback) {
        return (
            <Alert severity="info" className="mb-4">
                Решение еще не отправлено или находится на проверке
            </Alert>
        );
    }

    return (
        <Paper className="p-4 mt-4">
            <Typography variant="h6" gutterBottom>
                Результат проверки
            </Typography>

            {feedback.status === 'reviewing' && (
                <Alert severity="info" className="mb-4">
                    Ваше решение находится на проверке
                </Alert>
            )}

            {feedback.status === 'completed' && (
                <>
                    <Alert
                        severity={feedback.passed ? "success" : "error"}
                        className="mb-4"
                    >
                        {feedback.passed ? 'Задание выполнено успешно!' : 'Задание требует доработки'}
                    </Alert>

                    {feedback.automatic_checks?.length > 0 && (
                        <Box className="mb-4">
                            <Typography variant="subtitle1" gutterBottom>
                                Результаты автоматической проверки:
                            </Typography>
                            <List>
                                {feedback.automatic_checks.map((check, index) => (
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            {check.passed ? (
                                                <CheckCircleIcon className="text-green-500" />
                                            ) : (
                                                <ErrorIcon className="text-red-500" />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={check.name}
                                            secondary={check.message}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    )}

                    {feedback.mentor_feedback && (
                        <Box className="mb-4">
                            <Typography variant="subtitle1" gutterBottom>
                                Комментарий ментора:
                            </Typography>
                            <Paper className="p-3 bg-gray-50">
                                <Typography variant="body1">
                                    {feedback.mentor_feedback}
                                </Typography>
                            </Paper>
                        </Box>
                    )}

                    {!feedback.passed && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onRetry}
                            className="mt-4"
                        >
                            Доработать решение
                        </Button>
                    )}
                </>
            )}
        </Paper>
    );
};

export default AssignmentFeedback;