import React from 'react';

const Contact = () => {
    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Контакты</h1>
            <p className="text-gray-700 mb-4">
                Если у вас возникли вопросы или предложения, вы можете связаться с нами по электронной почте.
            </p>
            <p className="text-gray-800 font-semibold">
                Пишите на:{" "}
                <a
                    href="mailto:echinoidos@gmail.com"
                    className="text-blue-500 underline hover:text-blue-700"
                >
                    echinoidos@gmail.com
                </a>
            </p>
            <p className="text-gray-500 mt-4">Мы постараемся ответить на ваш запрос как можно скорее.</p>
        </div>
    );
};

export default Contact;
