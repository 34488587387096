import React from 'react';
import { Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const TextContent = ({ content }) => {
    return (
        <Typography component="div" className="lesson-text-content">
            <ReactMarkdown>{content}</ReactMarkdown>
        </Typography>
    );
};

export default TextContent;