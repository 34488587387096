import React, { useState, useCallback, useMemo } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    Alert,
    useTheme,
    IconButton,
    Tooltip
} from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { json } from '@codemirror/lang-json';
import { oneDark } from '@codemirror/theme-one-dark';
import { FormatAlignLeft as FormatIcon, PlayArrow as RunIcon } from '@mui/icons-material';
import { validateSQL, validateJSON, formatSQL, formatJSON } from '../../services/codeValidators';
import SQLPreview from './SQLPreview';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Quill.js стили

const AssignmentSubmissionDialog = ({ open, onClose, assignment, onSubmit, onTestRun }) => {
    const theme = useTheme();
    const [submission, setSubmission] = useState({
        content: '', // Используем для Quill редактора
        code: ''
    });
    const [error, setError] = useState('');
    const [previewData, setPreviewData] = useState(null);

    const isSQL = assignment?.code_language?.toLowerCase() === 'sql';
    const isJSON = assignment?.code_language?.toLowerCase() === 'json';
    const isTextAssignment = assignment?.code_language === undefined; // Если нет кода, то это текстовое задание

    // Валидация только выделенного кода
    const validation = useMemo(() => {
        if (isTextAssignment) return null; // Не валидируем текстовые задания
        const selectedCode = window.getSelection()?.toString() || submission.code;
        if (!selectedCode) return null;
        if (isSQL) return validateSQL(selectedCode);
        if (isJSON) return validateJSON(selectedCode);
        return null;
    }, [submission.code, isSQL, isJSON, isTextAssignment]);

    const handleFormat = () => {
        if (isTextAssignment) return; // Не форматируем текстовые задания
        try {
            const formatted = isSQL
                ? formatSQL(submission.code)
                : isJSON
                    ? formatJSON(submission.code)
                    : submission.code;

            setSubmission(prev => ({ ...prev, code: formatted }));
        } catch (error) {
            setError('Ошибка форматирования кода');
        }
    };

    const handleTestRun = async () => {
        if (!validation?.isValid) return;
        try {
            const results = await onTestRun(submission.code);
            setPreviewData(results);
            setError('');
        } catch (err) {
            setError(err.message || 'Ошибка выполнения запроса');
            setPreviewData(null);
        }
    };

    const handleCodeChange = useCallback((value) => {
        setSubmission(prev => ({ ...prev, code: value }));
        setError('');
    }, []);

    const handleQuillChange = (content) => {
        setSubmission(prev => ({ ...prev, content }));
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{ sx: { minHeight: '70vh' } }}
        >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{assignment.title}</Typography>
                    {!isTextAssignment && (
                        <Box>
                            <Tooltip title="Форматировать код">
                                <IconButton onClick={handleFormat}>
                                    <FormatIcon />
                                </IconButton>
                            </Tooltip>
                            {isSQL && (
                                <Tooltip title="Выполнить запрос">
                                    <IconButton
                                        onClick={handleTestRun}
                                        disabled={!validation?.isValid}
                                    >
                                        <RunIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    )}
                </Box>
            </DialogTitle>

            <DialogContent>
                <Box mb={3}>
                    <Typography variant="body1" paragraph>
                        {assignment.description}
                    </Typography>
                </Box>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                {/* Рендерим либо Quill, либо CodeMirror в зависимости от задания */}
                {isTextAssignment ? (
                    <ReactQuill
                        theme="snow"
                        value={submission.content}
                        onChange={handleQuillChange}
                    />
                ) : (
                    <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 1, mb: 3 }}>
                        <CodeMirror
                            value={submission.code}
                            height="300px"
                            theme={theme.palette.mode === 'dark' ? oneDark : undefined}
                            extensions={isSQL ? [sql()] : isJSON ? [json()] : []}
                            onChange={handleCodeChange}
                            basicSetup={{
                                lineNumbers: true,
                                highlightActiveLineGutter: true,
                                highlightSpecialChars: true,
                                history: true,
                                foldGutter: true,
                                drawSelection: true,
                                dropCursor: true,
                                allowMultipleSelections: true,
                                indentOnInput: true,
                                syntaxHighlighting: true,
                                bracketMatching: true,
                                closeBrackets: true,
                                autocompletion: true,
                                rectangularSelection: true,
                                crosshairCursor: true,
                                highlightActiveLine: true,
                                highlightSelectionMatches: true,
                                closeBracketsKeymap: true,
                                defaultKeymap: true,
                                searchKeymap: true,
                                historyKeymap: true,
                                foldKeymap: true,
                                completionKeymap: true,
                                lintKeymap: true,
                            }}
                        />
                    </Box>
                )}

                {validation && !validation.isValid && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        Ошибка в выделенном участке кода: {validation.error}
                    </Alert>
                )}

                {isSQL && validation?.isValid && (
                    <SQLPreview sql={submission.code} previewData={previewData} />
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSubmit(assignment.id, submission)}
                    disabled={!isTextAssignment && !validation?.isValid}
                >
                    Отправить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignmentSubmissionDialog;
