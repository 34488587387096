// Утилиты для работы с типами и генерации примеров

import React, { useState, useEffect } from 'react';
import { parse, visit } from 'graphql';
import {
    Typography,
    Button,
    Chip,
    IconButton,
    Paper,
    Dialog,
    DialogContent,
    TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const mapTypeToGraphQL = (type) => {
    switch (type.toLowerCase()) {
        case 'integer': return 'Int';
        case 'float': return 'Float';
        case 'string': return 'String';
        case 'boolean': return 'Boolean';
        default: return type;
    }
};

const generateExampleValue = (field) => {
    switch (field.type.toLowerCase()) {
        case 'integer': return 1;
        case 'float': return 1.0;
        case 'string': return `"example_${field.name}"`;
        case 'boolean': return true;
        default: return null;
    }
};

const generateCreateMutation = (entity) => {
    const fields = entity.fields
        .filter(field => !field.primary_key)
        .map(field => `      ${field.name}: ${generateExampleValue(field)}`)
        .join('\n');

    const responseFields = entity.fields
        .map(field => `    ${field.name}`)
        .join('\n');

    return `mutation {
  createEntity(
    entityName: "${entity.name}"
    input: {
${fields}
    }
  ) {
${responseFields}
  }
}`;
};

const generateQueryExample = (entity) => {
    const fields = entity.fields
        .map(field => `    ${field.name}`)
        .join('\n');

    return `query {
  allEntities(entityName: "${entity.name}") {
${fields}
  }
}`;
};

const generateSingleEntityQuery = (entity) => {
    const fields = entity.fields
        .map(field => `    ${field.name}`)
        .join('\n');

    return `query {
  entity(entityName: "${entity.name}", id: 1) {
${fields}
  }
}`;
};

const generateUpdateMutation = (entity) => {
    const fields = entity.fields
        .filter(field => !field.primary_key)
        .map(field => `      ${field.name}: ${generateExampleValue(field)}`)
        .join('\n');

    const responseFields = entity.fields
        .map(field => `    ${field.name}`)
        .join('\n');

    return `mutation {
  updateEntity(
    entityName: "${entity.name}"
    id: 1
    input: {
${fields}
    }
  ) {
${responseFields}
  }
}`;
};

const generateDeleteMutation = (entity) => {
    return `mutation {
  deleteEntity(
    entityName: "${entity.name}"
    id: 1
  ) {
    success
    message
  }
}`;
};

// Функция для извлечения переменных из запроса
const extractVariablesFromQuery = (queryString) => {
    try {
        const ast = parse(queryString);
        const variables = {};

        visit(ast, {
            Variable(node) {
                const varName = node.name.value;
                if (!variables[varName]) {
                    variables[varName] = null;
                }
            }
        });

        const variablesWithValues = Object.keys(variables).reduce((acc, varName) => {
            acc[varName] = "value";
            return acc;
        }, {});

        return JSON.stringify(variablesWithValues, null, 2);
    } catch (error) {
        console.error('Failed to parse query:', error);
        return '{}';
    }
};

// Компонент для документации
const SchemaExplorer = ({ entity, onExampleSelect }) => {
    if (!entity) {
        return <Typography>No entity data available</Typography>;
    }

    return (
        <div className="space-y-4 border-b border-gray-200 pb-4 mb-4">
            <div className="border-b pb-2">
                <Typography variant="h6" className="text-lg font-medium">
                    {entity.name}
                </Typography>
                <Typography variant="body2" className="text-gray-600">
                    {entity.description}
                </Typography>
            </div>

            <div>
                <Typography variant="subtitle2" className="mb-2 font-semibold">Fields:</Typography>
                {entity.fields.map((field, index) => (
                    <div key={index} className="mb-2 p-2 bg-white rounded shadow-sm hover:shadow transition-shadow">
                        <div className="flex items-center justify-between">
                            <Typography variant="body2" className="font-mono">
                                {field.name}: <span className="text-blue-600">{mapTypeToGraphQL(field.type)}</span>
                                <span className="text-red-500">{field.nullable ? '' : '!'}</span>
                            </Typography>
                            {field.primary_key && (
                                <Chip
                                    size="small"
                                    label="ID"
                                    color="primary"
                                    sx={{ height: '20px' }}
                                />
                            )}
                        </div>
                        <Typography variant="caption" className="text-gray-600 block mt-1">
                            {field.description}
                        </Typography>
                    </div>
                ))}
            </div>

            <div>
                <Typography variant="subtitle2" className="mb-2 font-semibold">Example Queries:</Typography>
                <div className="space-y-2">
                    <Button
                        size="small"
                        variant="outlined"
                        fullWidth
                        onClick={() => onExampleSelect(generateQueryExample(entity))}
                        className="justify-start text-left normal-case"
                    >
                        Получить все {entity.name}s
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        fullWidth
                        onClick={() => onExampleSelect(generateSingleEntityQuery(entity))}
                        className="justify-start text-left normal-case"
                    >
                        Получить {entity.name}
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        fullWidth
                        onClick={() => onExampleSelect(generateCreateMutation(entity))}
                        className="justify-start text-left normal-case"
                    >
                        Создать {entity.name}
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        fullWidth
                        onClick={() => onExampleSelect(generateUpdateMutation(entity))}
                        className="justify-start text-left normal-case"
                    >
                        Обновить {entity.name}
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        fullWidth
                        onClick={() => onExampleSelect(generateDeleteMutation(entity))}
                        className="justify-start text-left normal-case"
                    >
                        Удалить {entity.name}
                    </Button>
                </div>
            </div>
        </div>
    );
};

// Компонент для сохраненных запросов
const SavedQueries = ({ onSelect, onClose }) => {
    const [savedQueries, setSavedQueries] = useState([]);

    useEffect(() => {
        const queries = JSON.parse(localStorage.getItem('graphqlQueries') || '[]');
        setSavedQueries(queries);
    }, []);

    return (
        <div className="absolute right-0 top-12 bg-white shadow-lg rounded-lg p-4 w-80 z-50">
            <div className="flex justify-between items-center mb-4">
                <Typography variant="h6">Сохраненные запросы</Typography>
                <IconButton size="small" onClick={onClose}>
                    <Close />
                </IconButton>
            </div>
            {savedQueries.length === 0 ? (
                <Typography variant="body2" className="text-gray-500">Нет сохраненных запросов</Typography>
            ) : (
                <div className="space-y-2">
                    {savedQueries.map((query, index) => (
                        <Paper
                            key={index}
                            className="p-2 cursor-pointer hover:bg-gray-50"
                            onClick={() => onSelect(query)}
                        >
                            <Typography variant="subtitle2">{query.name}</Typography>
                            <Typography variant="caption" className="text-gray-500">
                                {query.query.slice(0, 50)}...
                            </Typography>
                        </Paper>
                    ))}
                </div>
            )}
        </div>
    );
};

// Диалог сохранения запроса
const SaveQueryDialog = ({ open, onClose, onSave, query }) => {
    const [name, setName] = useState('');

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Typography variant="h6" className="mb-4">Сохранить запрос</Typography>
                <TextField
                    fullWidth
                    label="Название запроса"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mb-4"
                />
                <div className="flex justify-end gap-2">
                    <Button onClick={onClose}>Отмена</Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            onSave({ name, query });
                            onClose();
                            setName('');
                        }}
                        disabled={!name}
                    >
                        Сохранить
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export {
    SchemaExplorer,
    SavedQueries,
    SaveQueryDialog,
    generateQueryExample,
    generateCreateMutation,
    generateUpdateMutation,
    generateDeleteMutation,
    generateSingleEntityQuery,
    extractVariablesFromQuery,
    mapTypeToGraphQL,
    generateExampleValue
};