import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
    Container,
    Grid,
    Typography,
    Box,
    Button,
    Tab,
    Tabs,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Avatar,
    Chip,
    Divider,
    Rating,
} from '@mui/material';
import { Person, Assignment, Lock, LockOpen, Star } from '@mui/icons-material';
import { AuthContext } from '../App';
import { fetchCourseDetails, fetchEnrollments, enrollInCourse, fetchUserProfiles, fetchCourseReviews } from '../services/apiService';
import CourseReviews from '../components/courses/CourseReviews';

const CourseDetail = () => {
    const { courseId } = useParams();
    const [course, setCourse] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const { isAuthenticated, token } = useContext(AuthContext);
    const [isEnrolled, setIsEnrolled] = useState(false);
    const [enrollmentDetails, setEnrollmentDetails] = useState(null);
    const [mentors, setMentors] = useState([]);
    const [reviews, setReviews] = useState([]);
    const navigate = useNavigate();
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
        setActiveTab(0);
    };

    useEffect(() => {
        const loadCourseDetails = async () => {
            try {
                const details = await fetchCourseDetails(courseId);
                setCourse(details);

                if (isAuthenticated) {
                    const enrollments = await fetchEnrollments(token);
                    const courseEnrollment = enrollments.find(e => e.course === parseInt(courseId));
                    setIsEnrolled(!!courseEnrollment);
                    setEnrollmentDetails(courseEnrollment);

                    const mentorProfiles = await fetchUserProfiles(token, details.mentors);
                    setMentors(mentorProfiles);
                }

                const courseReviews = await fetchCourseReviews(courseId);
                setReviews(courseReviews);
            } catch (error) {
                console.error("Error loading course details:", error);
            }
        };
        loadCourseDetails();
    }, [courseId, isAuthenticated, token]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleEnroll = async () => {
        if (!isAuthenticated) {
            navigate('/login', { state: { from: `/courses/${courseId}` } });
            return;
        }
        try {
            await enrollInCourse(token, courseId);
            setIsEnrolled(true);
            const enrollments = await fetchEnrollments(token);
            const courseEnrollment = enrollments.find(e => e.course === parseInt(courseId));
            setEnrollmentDetails(courseEnrollment);
        } catch (error) {
            console.error("Error enrolling in course:", error);
        }
    };

    if (!course) {
        return <Typography>Loading...</Typography>;
    }

    const totalLessons = course.modules.reduce((acc, module) => acc + module.lessons.length, 0);

    return (
        <Container maxWidth="lg" className="py-8">
            <Grid container spacing={6}>
                <Grid item xs={12} md={8}>
                    <Typography variant="h4" component="h1" className="mb-6 font-bold text-primary">
                        {course.title}
                    </Typography>
                    <Typography variant="body1" className="py-4">
                        {showFullDescription ? course.description : `${course.description.slice(0, 150)}...`}
                        <Button onClick={toggleDescription} color="primary">
                            {showFullDescription ? 'Свернуть' : 'Подробнее'}
                        </Button>
                    </Typography>
                    <Box className="mb-6 flex flex-wrap gap-2">
                        <Chip icon={<Person />} label={`${course.mentors.length} mentor${course.mentors.length !== 1 ? 's' : ''}`} />
                        <Chip icon={<Assignment />} label={`${totalLessons} уроков`} />
                        <Chip
                            icon={<Star />}
                            label={
                                <Box display="flex" alignItems="center">
                                    <Rating value={course.average_rating || 0} readOnly size="small" />
                                    <Typography variant="body2" ml={1}>
                                        ({reviews.length} отзывов)
                                    </Typography>
                                </Box>
                            }
                        />
                    </Box>
                    <Divider className="mb-6" />
                    <Tabs value={activeTab} onChange={handleTabChange} className="mb-6">
                        <Tab label="О курсе" />
                        <Tab label="Программа курса" />
                        <Tab label="Преподаватели" />
                        <Tab label="Отзывы" />
                    </Tabs>
                    <Box className="p-6 bg-gray-50 rounded-lg">
                        {activeTab === 0 && (
                            <Typography variant="body1">{course.description}</Typography>
                        )}
                        {activeTab === 1 && (
                            <List>
                                {course.modules.map((module) => (
                                    <React.Fragment key={module.id}>
                                        <ListItem>
                                            <ListItemText
                                                primary={<Typography variant="h6">{module.title}</Typography>}
                                                secondary={`${module.lessons.length} уроков`}
                                            />
                                        </ListItem>
                                        <List component="div" disablePadding>
                                            {module.lessons.map((lesson) => (
                                                <ListItem
                                                    key={lesson.id}
                                                    sx={{ pl: 4 }}
                                                    component={Link}
                                                    to={`/courses/${courseId}/lessons/${lesson.id}`}
                                                    disabled={lesson.is_locked && !isEnrolled}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Box display="flex" alignItems="center">
                                                                <Typography>{lesson.title}</Typography>
                                                                {lesson.is_locked ? <Lock fontSize="small" color="action" sx={{ ml: 1 }} /> : <LockOpen fontSize="small" color="action" sx={{ ml: 1 }} />}
                                                            </Box>
                                                        }
                                                        secondary={lesson.estimated_time ? `Примерное время: ${lesson.estimated_time} мин.` : null}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </React.Fragment>
                                ))}
                            </List>
                        )}
                        {activeTab === 2 && (
                            <Box>
                                {mentors.map((mentor) => (
                                    <Box key={mentor.id} display="flex" alignItems="center" mb={4}>
                                        <Avatar src={mentor.avatar} className="mr-4" sx={{ width: 64, height: 64 }} />
                                        <Box>
                                            <Typography variant="subtitle1" className="font-bold">{mentor.user.username}</Typography>
                                            <Typography variant="body2">{mentor.bio || 'Информация о преподавателе отсутствует'}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        )}
                        {activeTab === 3 && (
                            <CourseReviews reviews={reviews} />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className="sticky top-24">
                        <CardContent>
                            <Typography variant="h6" className="py-2">
                                {course.is_free ? "Бесплатно" : course.price ? `${course.price} руб.` : "Бесплатно"}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                className="mb-6 mt4"
                                onClick={handleEnroll}
                                disabled={isEnrolled}
                            >
                                {isEnrolled ? "Вы записаны" : "Записаться на курс"}
                            </Button>
                            {isEnrolled && enrollmentDetails && (
                                <Box>
                                    <Typography variant="body2">Прогресс: {enrollmentDetails.progress}%</Typography>
                                    {enrollmentDetails.last_accessed_lesson && (
                                        <Typography variant="body2">
                                            Последний урок: {enrollmentDetails.last_accessed_lesson.title}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                            <Typography variant="body1" className="py-2 font-semibold">
                                Этот курс включает:
                            </Typography>
                            <List dense>
                                <ListItem>
                                    <ListItemText primary={`${totalLessons} уроков`} />
                                </ListItem>
                                {course.has_paid_features && (
                                    <ListItem>
                                        <ListItemText primary="Платные функции" />
                                    </ListItem>
                                )}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default CourseDetail;