import { useLayoutEffect, useCallback, useEffect, useRef, useState } from 'react';

const useCanvasHandlers = ({
                               containerRef,
                               stageRef,
                               dimensions,
                               setDimensions,
                               setScale,
                               setPosition,
                               setContextMenu,
                           }) => {
    const dimensionsRef = useRef({ width: 0, height: 0 });
    const [isDragging, setIsDragging] = useState(false); // Состояние для отслеживания перетаскивания холста
    const lastMousePosition = useRef({ x: 0, y: 0 }); // Для хранения последней позиции мыши

    // Обновление размеров холста при изменении размера окна
    useLayoutEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                const newDimensions = {
                    width: containerRef.current.clientWidth,
                    height: containerRef.current.clientHeight,
                };

                if (
                    newDimensions.width !== dimensionsRef.current.width ||
                    newDimensions.height !== dimensionsRef.current.height
                ) {
                    dimensionsRef.current = newDimensions;
                    setDimensions(newDimensions);
                }
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, [setDimensions, containerRef]);

    /*
    // Обработчик зума колесом мыши
    const handleWheel = useCallback((e) => {
        e.preventDefault();

        const scaleBy = 1.1;
        const stage = stageRef.current;
        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        if (!pointer) return; // Проверка на существование pointer

        const mousePointTo = {
            x: pointer.x / oldScale - stage.x() / oldScale,
            y: pointer.y / oldScale - stage.y() / oldScale,
        };

        const newScale = e.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
        setScale(newScale);

        const newPos = {
            x: -(mousePointTo.x - pointer.x / newScale) * newScale,
            y: -(mousePointTo.y - pointer.y / newScale) * newScale,
        };
        setPosition(newPos);

        stage.scale({ x: newScale, y: newScale });
        stage.position(newPos);
        stage.batchDraw();
    }, [setScale, setPosition, stageRef]);

    // Добавление событий колесика
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('wheel', handleWheel, { passive: false });
        }

        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel);
            }
        };
    }, [handleWheel, containerRef]); */

    // Обработчик нажатия мыши на пустую область холста
    const handleMouseDown = useCallback((e) => {
        const stage = stageRef.current;
        const target = e.target;

        // Если нажали на сам холст, а не на компонент
        if (target === stage) {
            setIsDragging(true); // Начало перетаскивания холста
            const pointer = stage.getPointerPosition();
            lastMousePosition.current = pointer; // Сохраняем начальную позицию мыши
        }
    }, [stageRef]);

    // Обработчик движения мыши
    const handleMouseMove = useCallback((e) => {
        if (!isDragging) return; // Перетаскивание только если нажата кнопка и перетаскивается холст

        const stage = stageRef.current;
        const pointer = stage.getPointerPosition();

        // Рассчитываем смещение
        const dx = pointer.x - lastMousePosition.current.x;
        const dy = pointer.y - lastMousePosition.current.y;

        lastMousePosition.current = pointer; // Обновляем последнюю позицию мыши

        // Обновляем позицию холста
        const newPos = {
            x: stage.x() + dx,
            y: stage.y() + dy,
        };

        setPosition(newPos);
        stage.position(newPos);
        stage.batchDraw();
    }, [isDragging, setPosition, stageRef]);

    // Обработчик отпускания мыши
    const handleMouseUp = useCallback(() => {
        setIsDragging(false); // Заканчиваем перетаскивание холста
    }, []);

    // Добавление обработчиков для событий мыши
    useEffect(() => {
        const stage = stageRef.current;
        if (stage) {
            stage.on('mousedown', handleMouseDown);
            stage.on('mousemove', handleMouseMove);
            stage.on('mouseup', handleMouseUp);
            stage.on('mouseleave', handleMouseUp); // Прерываем перетаскивание при выходе за пределы холста
        }

        return () => {
            if (stage) {
                stage.off('mousedown', handleMouseDown);
                stage.off('mousemove', handleMouseMove);
                stage.off('mouseup', handleMouseUp);
                stage.off('mouseleave', handleMouseUp);
            }
        };
    }, [handleMouseDown, handleMouseMove, handleMouseUp, stageRef]);

};

export default useCanvasHandlers;
