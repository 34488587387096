import React, { useRef, useEffect } from 'react';

const ContextMenu = ({ position, onAction, componentName }) => {
    const contextMenuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                onAction(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onAction]);

    const handleAction = (action) => {
        if (action === 'describe') {
            onAction('describe', `Объясни что такое ${componentName} и как с этим работать`);
        } else {
            onAction(action);
        }
    };

    return (
        <div
            ref={contextMenuRef}
            className="absolute bg-white border border-gray-200 shadow-lg rounded-md z-50 min-w-[150px] text-sm"
            style={{ top: position.y, left: position.x }}
        >
            <div
                className="p-2 cursor-pointer hover:bg-blue-500 hover:text-white"
                onClick={() => handleAction('information')}
            >
                Документация
            </div>
            <div
                className="p-2 cursor-pointer hover:bg-blue-500 hover:text-white"
                onClick={() => handleAction('describe')}
            >
                Объясни что это
            </div>
        </div>
    );
};

export default ContextMenu;