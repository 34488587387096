import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';

const ImageContent = ({ url, alt }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box className="lesson-image-content">
            <img
                src={url}
                alt={alt}
                style={{ maxWidth: '100%', height: 'auto', cursor: 'pointer' }}
                onClick={handleOpen}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '90%',
                    maxHeight: '90%',
                    overflow: 'auto',
                }}>
                    <img
                        src={url}
                        alt={alt}
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Box>
            </Modal>
        </Box>
    );
};

export default ImageContent;