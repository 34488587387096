import React from 'react';

const DraggableHeader = ({ title, onMinimize }) => {
    return (
        <div
            className="bg-black px-2 py-1 text-white text-sm font-sans p-1 rounded-t-md shadow-lg flex justify-between items-center draggable-header w-full z-50"
        >
            <span>{title}</span>
            <button
                onClick={onMinimize}
                className="bg-black text-white hover:bg-black px-1 py-1 rounded"
            >
                ―
            </button>
        </div>
    );
};

export default DraggableHeader;
