import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const CourseFilters = ({ categories, selectedCategory, onCategoryChange, priceFilter, onPriceFilterChange }) => {
    const selectStyle = {
        fontSize: '0.9rem',
        '& .MuiSelect-select': {
            paddingTop: '2px',
            paddingBottom: '2px',
        },
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel sx={{ fontSize: '0.9rem' }}>Категория</InputLabel>
                <Select
                    value={selectedCategory}
                    onChange={onCategoryChange}
                    label="Категория"
                    sx={selectStyle}
                >
                    <MenuItem value="">Все категории</MenuItem>
                    {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel sx={{ fontSize: '0.9rem' }}>Цена</InputLabel>
                <Select
                    value={priceFilter}
                    onChange={onPriceFilterChange}
                    label="Цена"
                    sx={selectStyle}
                >
                    <MenuItem value="all">Все курсы</MenuItem>
                    <MenuItem value="free">Бесплатные</MenuItem>
                    <MenuItem value="paid">Платные</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default CourseFilters;