import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import AceEditor from 'react-ace';
import {
    Select,
    MenuItem,
    TextField,
    Button,
    Typography,
    Paper,
    Alert,
    Switch,
    FormControlLabel,
    Chip,
    IconButton,
    Tooltip,
    CircularProgress
} from '@mui/material';
import {
    PlayArrow as PlayArrowIcon,
    ContentCopy as CopyIcon,
    History as HistoryIcon,
    FormatIndentIncrease as FormatIcon,
    Close as CloseIcon,
} from '@mui/icons-material';

// ACE editor imports
import 'ace-builds';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';

// Утилиты для стилизации методов
const getMethodColor = (method) => {
    switch (method) {
        case 'GET':
            return 'success';
        case 'POST':
            return 'info';
        case 'PUT':
            return 'warning';
        case 'PATCH':
            return 'warning';
        case 'DELETE':
            return 'error';
        default:
            return 'default';
    }
};

const RestTab = ({ activeComponent }) => {
    const [method, setMethod] = useState('GET');
    const [url, setUrl] = useState('');
    const [requestBody, setRequestBody] = useState('');
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEndpoint, setSelectedEndpoint] = useState(null);
    const [jsonError, setJsonError] = useState(null);
    const [formatOnSend, setFormatOnSend] = useState(true);
    const [history, setHistory] = useState([]);
    const [showHistory, setShowHistory] = useState(false);
    const [copiedField, setCopiedField] = useState(null);

    // Эффект для установки URL при изменении активного компонента
    useEffect(() => {
        if (activeComponent?.host) {
            setUrl(activeComponent.host);
        }
    }, [activeComponent]);

    // Копирование в буфер обмена
    const copyToClipboard = (text, fieldName) => {
        navigator.clipboard.writeText(text);
        setCopiedField(fieldName);
        setTimeout(() => setCopiedField(null), 2000);
    };

    // Функция валидации JSON
    const validateJson = (jsonString) => {
        try {
            if (jsonString.trim()) {
                JSON.parse(jsonString);
                setJsonError(null);
                return true;
            }
            return true;
        } catch (error) {
            setJsonError(error.message);
            return false;
        }
    };

    // Обработчик изменения тела запроса
    const handleRequestBodyChange = (value) => {
        setRequestBody(value);
        validateJson(value);
    };

    // Форматирование JSON
    const formatJson = () => {
        try {
            const parsed = JSON.parse(requestBody);
            setRequestBody(JSON.stringify(parsed, null, 2));
            setJsonError(null);
        } catch (error) {
            setJsonError('Cannot format invalid JSON');
        }
    };

    // Обработчик выбора эндпоинта
    const handleEndpointSelect = (endpoint) => {
        setSelectedEndpoint(endpoint);
        setUrl(`${activeComponent.host}${endpoint.route}`);
        setMethod(endpoint.methods[0]);
        setJsonError(null);

        // Генерация примера тела запроса для POST/PUT/PATCH
        if (['POST', 'PUT', 'PATCH'].includes(endpoint.methods[0]) && endpoint.inputs) {
            const example = {};
            endpoint.inputs.forEach(input => {
                example[input.name] = getExampleValue(input.type);
            });
            setRequestBody(JSON.stringify(example, null, 2));
        } else {
            setRequestBody('');
        }
    };

    // Получение примерного значения по типу
    const getExampleValue = (type) => {
        switch (type.toLowerCase()) {
            case 'string':
                return 'example';
            case 'number':
            case 'integer':
                return 0;
            case 'boolean':
                return false;
            case 'array':
                return [];
            case 'object':
                return {};
            default:
                return null;
        }
    };

    // Отправка запроса
    const handleSendRequest = async () => {
        if (!url) return;

        if (method !== 'GET' && method !== 'DELETE') {
            if (!validateJson(requestBody)) {
                return;
            }

            if (formatOnSend) {
                formatJson();
            }
        }

        setIsLoading(true);
        const startTime = Date.now();

        try {
            const options = {
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            if (method !== 'GET' && method !== 'DELETE' && requestBody) {
                options.body = requestBody;
            }

            const res = await fetch(url, options);
            const data = await res.json();
            const endTime = Date.now();

            setResponse({
                status: res.status,
                statusText: res.statusText,
                headers: Object.fromEntries(res.headers.entries()),
                data,
                time: endTime - startTime
            });

            // Добавляем в историю
            setHistory(prev => [{
                method,
                url,
                body: requestBody,
                response: data,
                status: res.status,
                timestamp: new Date(),
                time: endTime - startTime
            }, ...prev].slice(0, 10));

        } catch (error) {
            setResponse({
                error: error.message,
                time: Date.now() - startTime
            });
        } finally {
            setIsLoading(false);
        }
    };

    const endpoints = activeComponent?.configuration?.endpoints || [];

    return (
        <div className="p-4">
            <div className="grid gap-4">
                {/* Секция эндпоинтов */}
                <Paper elevation={0} className="bg-gray-100 p-3 rounded-lg shadow-md">
                    <Typography variant="subtitle2" className="font-semibold text-gray-700 mb-2">
                        Доступные эндпоинты
                    </Typography>
                    <div className="grid gap-1">
                        {endpoints.map((endpoint, index) => (
                            <motion.div
                                key={index}
                                whileHover={{ scale: 1.01 }}
                                className={`px-2 py-1 rounded cursor-pointer bg-white hover:bg-gray-50 transition-colors ${
                                    selectedEndpoint === endpoint ? 'ring-2 ring-blue-500' : ''
                                }`}
                                onClick={() => handleEndpointSelect(endpoint)}
                            >
                                <div className="flex items-center w-full">
                                    <div className="flex-shrink-0 flex gap-1 mr-2">
                                        {endpoint.methods.map(m => (
                                            <Chip
                                                key={m}
                                                label={m}
                                                size="small"
                                                color={getMethodColor(m)}
                                                className="font-mono text-xs"
                                                sx={{ height: '20px' }}
                                            />
                                        ))}
                                    </div>
                                    <Typography className="font-mono text-sm text-gray-700 flex-shrink-0">
                                        {endpoint.route}
                                    </Typography>
                                    {endpoint.description && (
                                        <Typography
                                            variant="caption"
                                            className="text-gray-500 truncate ml-2 flex-grow"
                                            sx={{ minWidth: 0 }} // Важно для работы truncate
                                        >
                                            • {endpoint.description}
                                        </Typography>
                                    )}
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </Paper>

                {/* Секция запроса */}
                <Paper elevation={0} className="bg-gray-100 p-3 rounded-lg shadow-md">
                    <div className="flex gap-2 mb-3">
                        <Select
                            value={method}
                            onChange={(e) => setMethod(e.target.value)}
                            size="small"
                            className="w-20 bg-white"
                            sx={{height: '32px'}}
                        >
                            <MenuItem value="GET">GET</MenuItem>
                            <MenuItem value="POST">POST</MenuItem>
                            <MenuItem value="PUT">PUT</MenuItem>
                            <MenuItem value="PATCH">PATCH</MenuItem>
                            <MenuItem value="DELETE">DELETE</MenuItem>
                        </Select>

                        <div className="flex-grow relative">
                            <TextField
                                fullWidth
                                size="small"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                placeholder="Enter URL"
                                className="bg-white rounded"
                                sx={{
                                    '& .MuiInputBase-root': { height: '32px' },
                                    '& .MuiInputBase-input': {
                                        textOverflow: 'ellipsis'
                                    }
                                }}
                            />
                            {url && (
                                <Tooltip title="Копировать URL">
                                    <IconButton
                                        size="small"
                                        className="absolute right-1 top-1/2 -translate-y-1/2"
                                        sx={{padding: '4px'}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            copyToClipboard(url, 'URL');
                                        }}
                                    >
                                        <CopyIcon fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>

                        <div className="flex gap-1">
                            <Tooltip title="История запросов">
                                <IconButton
                                    size="small"
                                    onClick={() => setShowHistory(!showHistory)}
                                    className={showHistory ? 'bg-blue-50' : ''}
                                    sx={{height: '32px', width: '32px'}}
                                >
                                    <HistoryIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                            <Button
                                variant="contained"
                                disabled={isLoading || !url}
                                onClick={handleSendRequest}
                                startIcon={isLoading ? <CircularProgress size={16}/> : <PlayArrowIcon/>}
                                size="small"
                                sx={{height: '32px', textTransform: 'none'}}
                            >
                                {isLoading ? 'Отправка...' : 'Отправить'}
                            </Button>
                        </div>
                    </div>

                    {/* Тело запроса для POST/PUT/PATCH */}
                    {(method === 'POST' || method === 'PUT' || method === 'PATCH') && (
                        <div className="mb-4">
                            <div className="flex justify-between items-center mb-2">
                                <Typography variant="subtitle2" className="text-gray-700">
                                    Тело запроса
                                </Typography>
                                <div className="flex items-center gap-4">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={formatOnSend}
                                                onChange={(e) => setFormatOnSend(e.target.checked)}
                                                size="small"
                                            />
                                        }
                                        label={
                                            <Typography variant="caption">
                                                Форматировать при отправке
                                            </Typography>
                                        }
                                    />
                                    <Button
                                        size="small"
                                        onClick={formatJson}
                                        disabled={!requestBody}
                                        startIcon={<FormatIcon />}
                                    >
                                        Форматировать
                                    </Button>
                                </div>
                            </div>

                            {jsonError && (
                                <Alert severity="error" className="mb-2">
                                    {jsonError}
                                </Alert>
                            )}

                            <Paper elevation={0} className="bg-white">
                                <AceEditor
                                    mode="json"
                                    theme="tomorrow"
                                    value={requestBody}
                                    onChange={handleRequestBodyChange}
                                    name="request-body-editor"
                                    editorProps={{ $blockScrolling: true }}
                                    width="100%"
                                    height="200px"
                                    fontSize={14}
                                    showPrintMargin={false}
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true,
                                        showLineNumbers: true,
                                        tabSize: 2,
                                    }}
                                />
                            </Paper>
                        </div>
                    )}

                    {/* Ответ */}
                    {response && (
                        <div>
                            <div className="flex justify-between items-center mb-2">
                                <Typography variant="subtitle2" className="text-gray-700">
                                    Ответ
                                </Typography>
                                <div className="flex items-center gap-2">
                                    {response.status && (
                                        <Chip
                                            label={`${response.status} ${response.statusText}`}
                                            color={response.status >= 400 ? 'error' : 'success'}
                                            size="small"
                                        />
                                    )}
                                    {response.time && (
                                        <Chip
                                            label={`${response.time}ms`}
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                </div>
                            </div>
                            <Paper elevation={0} className="bg-white">
                                <AceEditor
                                    mode="json"
                                    theme="tomorrow"
                                    value={JSON.stringify(response.data || response, null, 2)}
                                    readOnly
                                    name="response-editor"
                                    editorProps={{ $blockScrolling: true }}
                                    width="100%"
                                    height="300px"
                                    fontSize={14}
                                    showPrintMargin={false}
                                    setOptions={{
                                        showLineNumbers: true,
                                        tabSize: 2,
                                    }}
                                />
                            </Paper>
                        </div>
                    )}
                </Paper>

                {/* История запросов */}
                {showHistory && (
                    <Paper elevation={0} className="bg-gray-100 p-4 rounded-lg shadow-md">
                        <div className="flex justify-between items-center mb-3">
                            <Typography variant="subtitle1" className="font-semibold text-gray-700">
                                История запросов
                            </Typography>
                            <IconButton size="small" onClick={() => setShowHistory(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className="space-y-2">
                            {history.map((item, index) => (
                                <Paper
                                    key={index}
                                    className="p-3 hover:bg-gray-50 cursor-pointer bg-white"
                                    onClick={() => {
                                        setMethod(item.method);
                                        setUrl(item.url);
                                        setRequestBody(item.body || '');
                                        setShowHistory(false);
                                    }}
                                >
                                    <div className="flex justify-between items-center mb-2">
                                        <div className="flex items-center gap-2">
                                            <Chip
                                                label={item.method}
                                                size="small"
                                                color={getMethodColor(item.method)}
                                                className="font-mono"
                                            />
                                            <Typography variant="caption" className="text-gray-500">
                                                {new Date(item.timestamp).toLocaleString()}
                                            </Typography>
                                        </div>
                                        <div className="flex gap-2">
                                            <Chip
                                                label={`${item.status || 'ERROR'}`}
                                                size="small"
                                                color={item.status >= 400 ? 'error' : 'success'}
                                            />
                                            {item.time && (
                                                <Chip
                                                    label={`${item.time}ms`}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <Typography variant="body2" className="font-mono truncate text-gray-700">
                                        {item.url}
                                    </Typography>
                                </Paper>
                            ))}
                            {history.length === 0 && (
                                <Typography className="text-center text-gray-500 py-4">
                                    История запросов пуста
                                </Typography>
                            )}
                        </div>
                    </Paper>
                )}
            </div>

            {/* Уведомление о копировании */}
            {copiedField && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    className="fixed bottom-4 right-4 bg-gray-800 text-white px-4 py-2 rounded shadow-lg z-50"
                >
                    {copiedField} скопирован
                </motion.div>
            )}
        </div>
    );
};

export default RestTab;