import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
// Поддержка языков
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/python/python';
// Дополнительные функции
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/selection/active-line';

const CodeEditor = ({
                        value,
                        onChange,
                        language = 'sql',
                        theme = 'material',
                        readOnly = false
                    }) => {
    const options = {
        mode: language,
        theme: theme,
        lineNumbers: true,
        lineWrapping: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        styleActiveLine: true,
        readOnly: readOnly,
        viewportMargin: Infinity,
        tabSize: 2,
        indentWithTabs: true
    };

    return (
        <CodeMirror
            value={value}
            options={options}
            onBeforeChange={(editor, data, value) => {
                onChange(value);
            }}
            className="border rounded"
        />
    );
};

export default CodeEditor;