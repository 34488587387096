import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
    Button,
    CircularProgress,
    Alert,
    Paper,
    Typography,
    Chip,
    Tooltip,
    IconButton,
} from '@mui/material';
import {
    PlayArrow as PlayArrowIcon,
    Save as SaveIcon,
    Info as InfoIcon,
    Timer as TimerIcon,
    Close as CloseIcon
} from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { submitAssignment } from '../../../services/apiService';
import CodeEditor from '../../CodeEditor';

const AssignmentTab = ({ assignment, assignmentStatus, token }) => {
    const [solution, setSolution] = useState('');
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDescription, setShowDescription] = useState(true);
    const [codeSubmission, setCodeSubmission] = useState('');
    const [textSubmission, setTextSubmission] = useState('');
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        if (assignment) {
            setLoading(false);
        } else {
            setError('Не удалось загрузить задание');
            setLoading(false);
        }
    }, [assignment]);

    const handleSubmit = async () => {
        if (!assignment) return;

        try {
            let submissionData;
            switch(assignment.type) {
                case 'code':
                    submissionData = { code: codeSubmission };
                    break;
                case 'text':
                    submissionData = { content: textSubmission };
                    break;
                default:
                    submissionData = { solution: solution };
            }

            setLoading(true);
            await submitAssignment(token, assignment.id, submissionData);
            setStatus({ type: 'success', message: 'Решение отправлено на проверку' });
            setIsDirty(false);
        } catch (error) {
            setStatus({ type: 'error', message: error.message || 'Ошибка при отправке решения' });
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <CircularProgress />
            </div>
        );
    }

    if (error || !assignment) {
        return (
            <div className="p-4">
                <Alert severity="error">
                    {error || 'Задание не найдено'}
                </Alert>
            </div>
        );
    }

    return (
        <div className="relative">
            {/* Заголовок и описание */}
            <div className="text-sm font-mono text-gray-700 bg-gray-100 p-4 rounded-lg shadow-md">
                <div className="flex justify-between items-center mb-4">
                    <Typography className="text-lg font-semibold text-gray-700">
                        {assignment.title}
                    </Typography>
                    <div className="flex items-center gap-2">
                        {assignmentStatus?.deadline && (
                            <Chip
                                size="small"
                                icon={<TimerIcon sx={{ fontSize: '0.9rem' }} />}
                                label={`До ${new Date(assignmentStatus.deadline).toLocaleDateString()}`}
                                color={new Date(assignmentStatus.deadline) < new Date() ? "error" : "primary"}
                                variant="outlined"
                            />
                        )}
                        {assignmentStatus?.is_completed && (
                            <Chip
                                size="small"
                                label="Выполнено"
                                color="success"
                                variant="filled"
                            />
                        )}
                        <Tooltip title={showDescription ? "Скрыть описание" : "Показать описание"}>
                            <IconButton
                                size="small"
                                onClick={() => setShowDescription(!showDescription)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                {showDescription ? <CloseIcon /> : <InfoIcon />}
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>

                <motion.div
                    initial={false}
                    animate={{ height: showDescription ? 'auto' : 0, opacity: showDescription ? 1 : 0 }}
                    transition={{ duration: 0.2 }}
                    className="overflow-hidden"
                >
                    <div className="prose max-w-none text-gray-700 mb-4"
                         dangerouslySetInnerHTML={{ __html: assignment.description }} />

                    {assignment.criteria && (
                        <div className="mt-4 bg-blue-50 p-4 rounded-lg">
                            <Typography variant="subtitle2" className="font-semibold mb-2 text-blue-700">
                                Критерии выполнения:
                            </Typography>
                            <ul className="list-disc pl-4 space-y-1">
                                {assignment.criteria.map((criterion, index) => (
                                    <li key={index} className="text-gray-700">{criterion}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </motion.div>
            </div>

            {/* Редактор решения */}
            <Paper elevation={0} className="mt-4 bg-white">
                {assignment.type === 'code' ? (
                    <CodeEditor
                        value={codeSubmission}
                        onChange={(value) => {
                            setCodeSubmission(value);
                            setIsDirty(true);
                        }}
                        language={assignment.language || 'sql'}
                        theme="light"
                        className="min-h-[400px]"
                    />
                ) : assignment.type === 'text' ? (
                    <ReactQuill
                        value={textSubmission}
                        onChange={(value) => {
                            setTextSubmission(value);
                            setIsDirty(true);
                        }}
                        theme="snow"
                        modules={{
                            toolbar: [
                                [{ 'header': [1, 2, false] }],
                                ['bold', 'italic', 'underline'],
                                ['code-block', 'link'],
                                [{ 'list': 'ordered'}, { 'list': 'bullet' }]
                            ]
                        }}
                        className="min-h-[400px]"
                    />
                ) : (
                    <textarea
                        value={solution}
                        onChange={(e) => {
                            setSolution(e.target.value);
                            setIsDirty(true);
                        }}
                        className="w-full min-h-[400px] p-4 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        placeholder="Введите ваше решение..."
                    />
                )}

                {/* Статус и действия */}
                <div className="p-4 border-t border-gray-200 bg-gray-50">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                            {status && (
                                <Alert severity={status.type} className="py-0">
                                    {status.message}
                                </Alert>
                            )}
                        </div>
                        <div className="flex gap-2">
                            {isDirty && (
                                <Button
                                    variant="outlined"
                                    startIcon={<SaveIcon />}
                                    size="small"
                                    onClick={() => {/* Add save draft functionality */}}
                                >
                                    Сохранить черновик
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={loading || (!solution && !codeSubmission && !textSubmission)}
                                startIcon={loading ? <CircularProgress size={20} /> : <PlayArrowIcon />}
                                size="small"
                            >
                                {loading ? 'Отправка...' : 'Отправить решение'}
                            </Button>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export default AssignmentTab;