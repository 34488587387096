// src/utils/codeValidators.js

// SQL Parser из библиотеки node-sql-parser
import { Parser } from 'node-sql-parser';

export const validateSQL = (sql) => {
    const parser = new Parser();
    try {
        const ast = parser.astify(sql);
        return {
            isValid: true,
            ast,
            error: null
        };
    } catch (error) {
        return {
            isValid: false,
            ast: null,
            error: error.message
        };
    }
};

export const validateJSON = (jsonString) => {
    try {
        const parsed = JSON.parse(jsonString);
        return {
            isValid: true,
            parsed,
            error: null
        };
    } catch (error) {
        return {
            isValid: false,
            parsed: null,
            error: error.message
        };
    }
};

export const formatSQL = (sql) => {
    const parser = new Parser();
    try {
        const ast = parser.astify(sql);
        return parser.sqlify(ast);
    } catch (error) {
        return sql;
    }
};

export const formatJSON = (jsonString) => {
    try {
        const parsed = JSON.parse(jsonString);
        return JSON.stringify(parsed, null, 2);
    } catch (error) {
        return jsonString;
    }
};