import React from 'react';
import { Layer, Line } from 'react-konva';

const Grid = ({ width, height, cellSize = 20 }) => {
    const lines = [];

    // Вертикальные линии
    for (let i = 0; i <= width; i += cellSize) {
        lines.push(
            <Line
                key={`v${i}`}
                points={[i, 0, i, height]}
                stroke="#e0e0e0"
                strokeWidth={0.5}
            />
        );
    }

    // Горизонтальные линии
    for (let i = 0; i <= height; i += cellSize) {
        lines.push(
            <Line
                key={`h${i}`}
                points={[0, i, width, i]}
                stroke="#e0e0e0"
                strokeWidth={0.5}
            />
        );
    }

    return <Layer>{lines}</Layer>;
};

export default Grid;