import React from 'react';

const About = () => {
    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">О нас</h1>
            <div className=" p-4 rounded">
                <p className="text-gray-700">
                    Мы — команда энтузиастов, которая создаёт инновационные решения для образования в сфере ИТ.
                    Наша цель — сделать сложные технологии доступными для всех,
                    позволяя легко учиться и проектировать системы с помощью простых инструментов.
                </p>
                <p className="mt-4 text-gray-700">
                    Мы верим, что будущее за автоматизацией и искусственным интеллектом, и стремимся предоставить лучшие
                    решения в этой области.
                </p>
            </div>
        </div>
    );
};

export default About;
