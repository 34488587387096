import React from 'react';
import { Box, Typography, Avatar, Rating } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';

const ReviewItem = ({ review }) => (
    <Box className="text-center px-4">
        <Avatar src={review.user.avatar} alt={review.user.username} className="mx-auto mb-2" />
        <Typography variant="subtitle1">{review.user.username}</Typography>
        <Rating value={review.rating} readOnly size="small" className="mb-2" />
        <Typography variant="body2">{review.review}</Typography>
        <Typography variant="caption" color="text.secondary">
            Курс: {review.course.title}
        </Typography>
    </Box>
);

const CourseReviews = ({ reviews }) => {
    if (reviews.length === 0) {
        return null;
    }

    return (
        <Box className="my-8">
            <Typography variant="h5" component="h2" className="mb-4 text-center font-semibold">
                Что говорят наши студенты
            </Typography>
            <Carousel showThumbs={false} showStatus={false} infiniteLoop autoPlay>
                {reviews.map((review, index) => (
                    <ReviewItem key={index} review={review} />
                ))}
            </Carousel>
        </Box>
    );
};

export default CourseReviews;