import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto px-6 py-12">
            <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">Политика конфиденциальности</h1>

            <p className="text-lg text-gray-700 mb-6">
                Данная Политика конфиденциальности описывает, как мы собираем, используем и защищаем ваши персональные данные
                при использовании нашего сайта или услуг.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Собираемая информация</h2>
            <p className="text-base text-gray-700 mb-6">
                Мы можем собирать такие данные, как ваш IP-адрес, электронная почта, имя и данные, связанные с использованием нашего сайта.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Как мы используем ваши данные</h2>
            <p className="text-base text-gray-700 mb-6">
                Ваши данные используются для улучшения наших услуг, предоставления персонализированного опыта и аналитики.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Передача данных</h2>
            <p className="text-base text-gray-700 mb-6">
                Мы не передаем ваши персональные данные третьим лицам, за исключением случаев, предусмотренных законом, или с вашего явного согласия.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Безопасность данных</h2>
            <p className="text-base text-gray-700 mb-6">
                Мы принимаем все разумные меры для защиты ваших данных от несанкционированного доступа.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Права пользователей</h2>
            <p className="text-base text-gray-700 mb-6">
                Вы имеете право запросить доступ, изменение и удаление ваших данных.
            </p>

            <p className="text-base text-center text-gray-600">
                Если у вас возникли вопросы по данной Политике конфиденциальности, пожалуйста, свяжитесь с нами.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
