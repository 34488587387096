// src/components/assignments/SQLPreview.js
import React, { useMemo } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Alert
} from '@mui/material';
import { validateSQL } from '../../services/codeValidators';

const SQLPreview = ({ sql, previewData }) => {
    const validation = useMemo(() => validateSQL(sql), [sql]);

    if (!validation.isValid) {
        return (
            <Alert severity="error" sx={{ mt: 2 }}>
                <Typography variant="subtitle2">Ошибка в SQL-запросе:</Typography>
                <Typography variant="body2">{validation.error}</Typography>
            </Alert>
        );
    }

    // Если есть тестовые данные для предпросмотра
    if (previewData?.columns && previewData?.rows) {
        return (
            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                    Результат выполнения запроса:
                </Typography>
                <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                {previewData.columns.map((column, index) => (
                                    <TableCell key={index}>{column}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {previewData.rows.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <TableCell key={cellIndex}>
                                            {cell?.toString() || 'NULL'}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }

    return (
        <Alert severity="success" sx={{ mt: 2 }}>
            SQL-запрос корректен
        </Alert>
    );
};

export default SQLPreview;