import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import { getProcessStage } from '../services/apiService';
import animationData from '../img/generator_loader.json'; // Убедись, что путь верный

const SandboxLoader = ({ token, ProcessId, SandboxId }) => {
    const [stage, setStage] = useState(1);
    const [isLoading] = useState(true); // Добавляем состояние для лоадера
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const processStage = await getProcessStage(token, ProcessId);

                if (processStage && processStage.length > 0) {
                    const currentStage = processStage[0].stage;
                    setStage(currentStage);

                    if (currentStage === 2) {
                        clearInterval(interval);
                        window.location.assign(`/sandbox/${SandboxId}`);
                    }
                }
            } catch (error) {
                console.error("Error fetching generation stage:", error);
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [token, ProcessId, navigate, SandboxId]);

    const stageText = {
        1: "Устраиваем мозговой штурм...",
        2: "Проектируем систему...",
        3: "Разрабатываем сервисы...",
        4: "Деплоим на прод...",
        5: "Наполняем тестовыми данными...",
        6: "Генерация завершена!",
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData || {},
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <>
            {/* Лоадер поверх всего */}
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    {/* Затемнение фона и блокировка взаимодействий */}
                    <div className="absolute inset-0 bg-white opacity-50 pointer-events-none"></div>

                    {/* Контент лоадера */}
                    <div className="relative z-10 text-center text-gray-800">
                        <div className="mb-4 text-xl bg-white bg-opacity-75 px-4 py-2 inline-block rounded-md">
                            {stageText[stage]}
                        </div>
                        <Lottie options={defaultOptions} height={400} width={400} />
                    </div>
                </div>
            )}
        </>
    );
};

export default SandboxLoader;
