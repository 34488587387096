// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Черный
    },
    secondary: {
      main: '#808080', // Серый
    },
    background: {
      default: '#FFFFFF', // Белый фон
      paper: '#F5F5F5', // Светло-серый для элементов
    },
    text: {
      primary: '#000000', // Черный текст
      secondary: '#808080', // Серый текст для акцентов
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px', // Округленные края для кнопок
        },
      },
    },
  },
});

export default theme;
