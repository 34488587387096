import React from 'react';
import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer style={{ padding: '20px 0', textAlign: 'center', backgroundColor: '#f8f9fa' }}>
            <Typography variant="body2" color="textSecondary">
                <Link href="/privacy-policy" color="inherit">
                    {t('footer.privacy_policy')}
                </Link>
                {' | '}
                <Link href="/contact" color="inherit">
                    {t('footer.contact_us')}
                </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
                {t('footer.copyright')}
            </Typography>
        </footer>
    );
};

export default Footer;
