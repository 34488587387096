import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import SandboxHeader from '../components/sandbox/SandboxHeader';
import SandboxCanvas from '../components/sandbox/SandboxCanvas';
import ComponentPanel from '../components/sandbox/ComponentPanel';
import LayersPanel from '../components/sandbox/LayersPanel';
import InfoPanel from '../components/sandbox/InfoPanel';
import {
    fetchSandboxes,
    fetchSandboxComponents,
    fetchSandboxComponentsRelations,
    getProcessStage,
    fetchInstances,
    getAssignmentDetails,
    getAssignmentStatus
} from '../services/apiService';
import SandboxChoice from '../components/sandbox/SandboxChoice';

const Sandbox = ({ token, userInfo }) => {
    const { sandboxId } = useParams();
    const [searchParams] = useSearchParams();
    const assignmentId = searchParams.get('assignment_id');
    const mode = searchParams.get('mode');

    const [sandboxData, setSandboxData] = useState(null);
    const [components, setComponents] = useState([]);
    const [relations, setRelations] = useState([]);
    const [activeComponent, setActiveComponent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [accessLevel, setAccessLevel] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [processStage, setProcessStage] = useState(0);
    const [updatingComponents, setUpdatingComponents] = useState(false);
    const [isUpdateComplete, setIsUpdateComplete] = useState(false);
    const [currentLayer, setCurrentLayer] = useState('Подробная архитектура');
    const [assignment, setAssignment] = useState(null);
    const [assignmentStatus, setAssignmentStatus] = useState(null);
    const [loadingAssignment, setLoadingAssignment] = useState(false);

    const containerRef = useRef(null);
    const [activeInfoTab, setActiveInfoTab] = useState(null);

    // Загрузка информации о задании, если это режим задания
    useEffect(() => {
        const loadAssignment = async () => {
            if (assignmentId && mode === 'assignment' && token) {
                setLoadingAssignment(true);
                try {
                    const [assignmentData, statusData] = await Promise.all([
                        getAssignmentDetails(token, assignmentId),
                        getAssignmentStatus(token, assignmentId)
                    ]);

                    setAssignment(assignmentData);
                    setAssignmentStatus(statusData);

                    // Автоматически открываем вкладку с заданием
                    setActiveInfoTab('Задание');
                } catch (error) {
                    console.error('Error loading assignment:', error);
                } finally {
                    setLoadingAssignment(false);
                }
            }
        };

        loadAssignment();
    }, [assignmentId, mode, token]);

    const handleInfoTabChange = useCallback((tabName) => {
        setActiveInfoTab(tabName);
    }, []);

    // Загрузка данных песочницы
    const loadSandboxData = useCallback(async () => {
        try {
            setLoading(true);

            const sandboxes = await fetchSandboxes(token, userInfo.id);
            const sandbox = sandboxes.find((s) => s.id === parseInt(sandboxId));

            const instances = await fetchInstances(token, parseInt(sandboxId));
            const instance = instances[0];
            if (sandbox && instance) {
                sandbox.process_id = instance.process;
            }

            if (!sandbox) {
                setAccessLevel('no-exist');
                setLoading(false);
                return;
            }

            if (sandbox.owner === userInfo.id) {
                setAccessLevel('owner');
            } else if (sandbox.coworkers?.includes(userInfo.id)) {
                setAccessLevel('coworker');
            } else {
                setAccessLevel('no-access');
            }

            setSandboxData(sandbox);

            if (sandbox.owner === userInfo.id || sandbox.coworkers?.includes(userInfo.id)) {
                let loadedComponents = await fetchSandboxComponents(token, sandboxId);
                loadedComponents = loadedComponents.filter(
                    (component) => component.category_name !== 'physical_deployment'
                );
                setComponents(loadedComponents);

                if (loadedComponents.length > 0) {
                    const componentIds = loadedComponents.map((comp) => comp.id);
                    const loadedRelations = await fetchSandboxComponentsRelations(
                        token,
                        sandboxId,
                        componentIds
                    );
                    setRelations(loadedRelations);
                }
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching sandbox data:', error);
            setLoading(false);
        }
    }, [sandboxId, token, userInfo.id]);

    const updateComponentsData = useCallback(async () => {
        setUpdatingComponents(true);
        try {
            let loadedComponents = await fetchSandboxComponents(token, sandboxId);
            loadedComponents = loadedComponents.filter(
                (component) => component.category_name !== 'physical_deployment'
            );

            // Сохраняем текущие позиции компонентов
            const currentPositions = components.reduce((acc, comp) => {
                acc[comp.id] = {x: comp.x, y: comp.y};
                return acc;
            }, {});

            // Обновляем компоненты, сохраняя их позиции
            const updatedComponents = loadedComponents.map(comp => ({
                ...comp,
                x: currentPositions[comp.id] ? currentPositions[comp.id].x : comp.x,
                y: currentPositions[comp.id] ? currentPositions[comp.id].y : comp.y,
            }));

            setComponents(updatedComponents);

            if (loadedComponents.length > 0) {
                const componentIds = loadedComponents.map((comp) => comp.id);
                const loadedRelations = await fetchSandboxComponentsRelations(
                    token,
                    sandboxId,
                    componentIds
                );
                setRelations(loadedRelations);
            }
        } catch (error) {
            console.error('Error updating components data:', error);
        } finally {
            setUpdatingComponents(false);
        }
    }, [token, sandboxId, components]);

    // Эффект загрузки данных при изменении sandboxId
    useEffect(() => {
        if (!sandboxId) {
            setShowModal(true);
            setLoading(false);
            return;
        }
        loadSandboxData();
    }, [sandboxId, loadSandboxData]);

    // Обновленный эффект для проверки статуса процесса
    useEffect(() => {
        if (!sandboxData || !sandboxData.process_id || isUpdateComplete) return;
        const processId = sandboxData.process_id;

        const interval = setInterval(async () => {
            try {
                const processStage = await getProcessStage(token, processId);

                if (processStage && processStage.length > 0) {
                    const currentStage = processStage[0].stage;
                    setProcessStage(currentStage);

                    if (currentStage >= 5 && !isUpdateComplete) {
                        await updateComponentsData();
                        setIsUpdateComplete(true);
                        clearInterval(interval);
                    }
                }
            } catch (error) {
                console.error('Error fetching process status:', error);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [sandboxData, token, updateComponentsData, isUpdateComplete, setProcessStage]);


    const filterComponentsByLayer = useCallback((components, layer) => {
        if (layer === 'Верхнеуровневая архитектура') {
            return components.filter(comp => comp.category_name === 'environment');
        } else {
            return components.filter(comp =>
                comp.category_name === 'backend' ||
                comp.category_name === 'frontend' ||
                (comp.category_name === 'environment' && comp.type_name === 'User')
            );
        }
    }, []);

    const createHighLevelArchitecture = useCallback((components, sandboxName) => {
        const environmentComponents = components.filter(comp => comp.category_name === 'environment');
        const systemComponent = {
            id: 'system',
            name: sandboxName,
            category_name: 'system',
            x: 0,
            y: 0,
            width: 200,
            height: 100,
        };
        return [...environmentComponents, systemComponent];
    }, []);

    const adjustRelationsForHighLevel = useCallback((relations, components) => {
        return relations.map(relation => {
            const fromComp = components.find(comp => comp.id === relation.from_component);
            const toComp = components.find(comp => comp.id === relation.to_component);
            if (fromComp && toComp) {
                if (fromComp.category_name === 'environment' &&
                    (toComp.category_name === 'backend' || toComp.category_name === 'frontend')) {
                    return {...relation, to_component: 'system'};
                }
                if (toComp.category_name === 'environment' &&
                    (fromComp.category_name === 'backend' || fromComp.category_name === 'frontend')) {
                    return {...relation, from_component: 'system'};
                }
            }
            return relation;
        }).filter(relation =>
            components.some(comp => comp.id === relation.from_component) &&
            components.some(comp => comp.id === relation.to_component)
        );
    }, []);

    const handleLayerChange = useCallback((layer) => {
        setCurrentLayer(layer);
    }, []);

    const filteredComponents = useMemo(() => {
        if (currentLayer === 'Верхнеуровневая архитектура') {
            return createHighLevelArchitecture(components, sandboxData?.name);
        } else {
            return filterComponentsByLayer(components, currentLayer);
        }
    }, [components, currentLayer, sandboxData, createHighLevelArchitecture, filterComponentsByLayer]);

    const filteredRelations = useMemo(() => {
        if (currentLayer === 'Верхнеуровневая архитектура') {
            return adjustRelationsForHighLevel(relations, components);
        } else {
            return relations.filter(relation =>
                filteredComponents.some(comp => comp.id === relation.from_component) &&
                filteredComponents.some(comp => comp.id === relation.to_component)
            );
        }
    }, [relations, currentLayer, components, filteredComponents, adjustRelationsForHighLevel]);

    if (loading || loadingAssignment) {
        return <div>Загрузка песочницы...</div>;
    }

    if (accessLevel === 'no-exist') {
        return <div>Песочница не существует</div>;
    }

    if (accessLevel === 'no-access') {
        return <div>У вас нет доступа к этой песочнице</div>;
    }

    return (
        <div className="w-full flex flex-col" style={{height: 'calc(100vh - 90px)'}}>
            {showModal && (
                <SandboxChoice token={token} userInfo={userInfo} onClose={() => setShowModal(false)} />
            )}

            <div className="flex-shrink-0">
                <SandboxHeader
                    token={token}
                    userInfo={userInfo}
                    sandboxName={sandboxData ? sandboxData.name : ''}
                    showRecreateButton={accessLevel === 'owner' && mode !== 'assignment'}
                    sandboxId={sandboxId}
                    stageRef={containerRef}
                />
            </div>

            <div className="flex-grow flex relative">
                <div className="flex-grow relative" ref={containerRef}>
                    <SandboxCanvas
                        components={filteredComponents}
                        setComponents={setComponents}
                        setActiveComponent={setActiveComponent}
                        relations={filteredRelations}
                        containerRef={containerRef}
                        updatingComponents={updatingComponents}
                        currentLayer={currentLayer}
                        sandboxName={sandboxData ? sandboxData.name : ''}
                        onLayerChange={handleLayerChange}
                        userInfo={userInfo}
                        token={token}
                        sandboxId={sandboxId}
                        activeComponent={activeComponent}
                        activeInfoTab={activeInfoTab}
                        processStage={processStage}
                        isAssignmentMode={mode === 'assignment'}
                    />
                </div>

                <div className="flex-shrink-0">
                    <InfoPanel
                        activeComponent={activeComponent}
                        onTabChange={handleInfoTabChange}
                        token={token}
                        assignment={assignment}
                        assignmentStatus={assignmentStatus}
                    />
                </div>

                <LayersPanel
                    currentLayer={currentLayer}
                    onLayerChange={handleLayerChange}
                />

                <ComponentPanel
                    components={filteredComponents}
                    setComponents={setComponents}
                    setActiveComponent={setActiveComponent}
                />
            </div>
        </div>
    );
};

export default Sandbox;