import React from 'react';
import { Box } from '@mui/material';

const VideoContent = ({ url }) => {
    const getEmbedUrl = (url) => {
        if (url.includes('youtube.com') || url.includes('youtu.be')) {
            const videoId = url.includes('v=') ? url.split('v=')[1].split('&')[0] : url.split('/').pop();
            return `https://www.youtube.com/embed/${videoId}`;
        } else if (url.includes('vimeo.com')) {
            const videoId = url.split('/').pop();
            return `https://player.vimeo.com/video/${videoId}`;
        } else {
            // Для других видеохостингов можно добавить дополнительные проверки
            return url;
        }
    };

    const embedUrl = getEmbedUrl(url);

    return (
        <Box sx={{ width: '100%', maxWidth: '640px', margin: '0 auto', aspectRatio: '16/9' }}>
            <iframe
                width="100%"
                height="100%"
                src={embedUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded video"
            />
        </Box>
    );
};

export default VideoContent;