import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Alert, CircularProgress, alpha } from '@mui/material';
import { Code as CodeIcon } from '@mui/icons-material';
import AssignmentFeedback from '../courses/AssignmentFeedback';
import { getAssignmentStatus } from '../../services/apiService';

const SandboxAssignment = ({
                               assignment,
                               token,
                               onOpenSandbox,
                               isDeploying,
                               hideContainer = false
                           }) => {
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadStatus = async () => {
            try {
                const data = await getAssignmentStatus(token, assignment.id);
                setStatus(data);
            } catch (error) {
                console.error('Error loading assignment status:', error);
            } finally {
                setLoading(false);
            }
        };

        if (assignment && token) {
            loadStatus();
        }
    }, [assignment, token]);

    const handleOpenSandbox = () => {
        onOpenSandbox(assignment);
    };

    const content = (
        <>
            {!hideContainer && (
                <>
                    <Typography variant="h6" sx={{ fontWeight: 500, mb: 1 }}>
                        {assignment.title}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 3 }}
                    >
                        {assignment.description}
                    </Typography>
                </>
            )}

            {status?.is_completed ? (
                <AssignmentFeedback
                    assignment_id={assignment.id}
                    token={token}
                    onRetry={handleOpenSandbox}
                />
            ) : (
                <Box sx={{ mt: hideContainer ? 0 : 2 }}>
                    <Button
                        variant="contained"
                        onClick={handleOpenSandbox}
                        startIcon={isDeploying ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            <CodeIcon />
                        )}
                        disabled={isDeploying}
                        sx={{
                            bgcolor: 'warning.main',
                            '&:hover': {
                                bgcolor: 'warning.dark'
                            },
                            '&.Mui-disabled': {
                                bgcolor: alpha('#FF5722', 0.3)
                            }
                        }}
                    >
                        {isDeploying
                            ? 'Подготовка окружения...'
                            : assignment.sandbox_environment?.status === 'active'
                                ? 'Открыть песочницу'
                                : 'Подготовить окружение'}
                    </Button>
                </Box>
            )}

            {assignment.deadline && (
                <Alert
                    severity="info"
                    sx={{
                        mt: 2,
                        '& .MuiAlert-icon': {
                            color: 'warning.main'
                        }
                    }}
                >
                    Срок сдачи: {new Date(assignment.deadline).toLocaleDateString()}
                </Alert>
            )}
        </>
    );

    if (loading) {
        return <Box sx={{ p: 2 }}>Loading...</Box>;
    }

    return hideContainer ? content : (
        <Box sx={{
            p: 3,
            borderRadius: 2,
            bgcolor: alpha('#FF5722', 0.05),
            border: '1px solid',
            borderColor: alpha('#FF5722', 0.1),
        }}>
            {content}
        </Box>
    );
};

export default SandboxAssignment;