import React, { useContext } from 'react';
import { Card, CardContent, CardMedia, Typography, Chip, Grid  } from '@mui/material';
import { Person, Lock } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';

const CourseCard = ({ course }) => {
    const { isAuthenticated, userInfo } = useContext(AuthContext);

    if (!course || !course.is_published) {
        return null;
    }

    const isAllowed = !course.private || (isAuthenticated && course.allowed_users.includes(userInfo?.id));

    if (!isAllowed) {
        return null;
    }

    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Link to={`/courses/${course.id}`} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardMedia
                    data-cmp-granular="true"
                    data-cmp-vendor="s26"
                    data-cmp-category="essential"
                    component="img"
                    height="140"
                    image={course.image_url || "/api/placeholder/400/200"}
                    alt={course.title || 'Course image'}
                    loading="lazy"
                    sx={{ objectFit: 'cover' }}
                />
                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 2 }}>
                    <div>
                        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: 'primary.main', mb: 1 }}>
                            {course.title || 'Untitled Course'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                            {course.description
                                ? (course.description.length > 100
                                    ? `${course.description.substring(0, 100)}...`
                                    : course.description)
                                : 'No description available'}
                        </Typography>
                    </div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                            {course.category && (
                                <Chip
                                    label={course.category.name || 'Uncategorized'}
                                    size="small"
                                    sx={{ backgroundColor: 'orange.100', color: 'primary.main' }}
                                />
                            )}
                            <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                <Person sx={{ fontSize: 'small', mr: 0.5, color: 'grey.400' }} />
                                {course.mentors && course.mentors.length > 0
                                    ? `${course.mentors.length} ${course.mentors.length === 1 ? 'ментор' : 'ментора'}`
                                    : 'Нет менторов'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {course.price === null ? (
                                <Chip
                                    label="Бесплатно"
                                    size="small"
                                    color="success"
                                    sx={{ fontWeight: 'bold' }}
                                />
                            ) : (
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                    {`${course.price} руб.`}
                                </Typography>
                            )}
                            {course.private && (
                                <Chip
                                    icon={<Lock fontSize="small" />}
                                    label="Приватный"
                                    size="small"
                                    color="default"
                                />
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Link>
        </Card>
    );
};

export default CourseCard;