// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en/translation.json';
import ru from './locales/ru/translation.json';

// Инициализация i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    ru: { translation: ru },
    // Добавьте другие языки здесь
  },
  lng: 'ru', // Язык по умолчанию
  fallbackLng: 'en', // Резервный язык
  interpolation: {
    escapeValue: false, // React уже предотвращает XSS
  },
});

export default i18n;

