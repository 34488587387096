import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';

// Функция для создания примера JSON на основе полей сущности
const generateEntityJsonExample = (entity) => {
    const example = {};
    entity.fields.forEach(field => {
        example[field.name] = field.type === 'integer' ? 123 : field.type === 'string' ? 'example' : null;
    });
    return example;
};

const InfoTab = ({ activeComponent }) => {
    const [selectedItem, setSelectedItem] = useState(null); // Состояние для отображения выбранного элемента (эндпоинт или сущность)
    const [copiedField, setCopiedField] = useState(null); // Состояние для отслеживания копирования

    const copyToClipboard = (text, field) => {
        navigator.clipboard.writeText(text);
        setCopiedField(field);
        setTimeout(() => setCopiedField(null), 2000);
    };

    const openDetails = (item) => {
        setSelectedItem(item);
    };

    const closeDetails = () => {
        setSelectedItem(null);
    };

    return (
        <div>
            {activeComponent ? (
                <div className="relative">
                    {/* Общая информация о компоненте */}
                    <div className="grid grid-cols-2 gap-y-4 text-sm font-mono text-gray-700 bg-gray-100 p-4 rounded-lg shadow-md">
                        <div className="font-semibold text-gray-600">Название:</div>
                        <div className="col-span-2 text-green-600">{activeComponent.name || 'Не указано'}</div>

                        <div className="font-semibold text-gray-600">Тип:</div>
                        <div className="col-span-2 text-blue-600">{activeComponent.type_name || 'Не указано'}</div>

                        {activeComponent.category_name === "backend" && (
                            <>
                                <div className="font-semibold text-gray-600">Хост:</div>
                                <div className="col-span-2 flex items-center text-yellow-600">
                                    {activeComponent.host || 'Не указано'}
                                    {activeComponent.host && (
                                        <button
                                            onClick={() => copyToClipboard(activeComponent.host, 'Хост')}
                                            className="ml-2 text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded hover:bg-gray-300"
                                        >
                                            <ContentCopyIcon fontSize="small" />
                                        </button>
                                    )}
                                </div>

                                {activeComponent.type_name === "Database" && (
                                    <>
                                        <div className="font-semibold text-gray-600">Порт:</div>
                                        <div className="col-span-2 flex items-center text-purple-600">
                                            {activeComponent.port || 'Не указано'}
                                            {activeComponent.port && (
                                                <button
                                                    onClick={() => copyToClipboard(activeComponent.port, 'Порт')}
                                                    className="ml-2 text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded hover:bg-gray-300"
                                                >
                                                    <ContentCopyIcon fontSize="small"/>
                                                </button>
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        <div className="font-semibold text-gray-600">Описание:</div>
                        <div className="col-span-2 text-gray-700">{activeComponent.description || 'Не указано'}</div>

                        {/* Эндпоинты для Microservice */}
                        {activeComponent.type_name === 'Microservice' && activeComponent.configuration?.endpoints && (
                            <>
                                <div className="col-span-2 font-semibold text-gray-600 mt-4">Эндпоинты:</div>
                                <div className="col-span-2 space-y-2">
                                    {activeComponent.configuration.endpoints.map((endpoint, index) => (
                                        <div
                                            key={index}
                                            className="text-blue-600 cursor-pointer hover:underline"
                                            onClick={() => openDetails(endpoint)}
                                        >
                                            {endpoint.methods.join(', ')} {endpoint.route}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {/* Сущности */}
                        {activeComponent.type_name === 'Microservice' && activeComponent.configuration?.entities && (
                            <>
                                <div className="col-span-2 font-semibold text-gray-600 mt-4">Сущности:</div>
                                <div className="col-span-2 space-y-2">
                                    {activeComponent.configuration.entities.map((entity, index) => (
                                        <div
                                            key={index}
                                            className="text-blue-600 cursor-pointer hover:underline"
                                            onClick={() => openDetails(entity)}
                                        >
                                            {entity.name}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>

                    {/* Всплывающее уведомление о копировании */}
                    {copiedField && (
                        <div className="absolute bottom-3 left-1/2 transform -translate-x-1/2 bg-gray-400 text-black px-4 py-2 rounded shadow-md transition-opacity duration-300">
                            {copiedField} скопирован в буфер обмена
                        </div>
                    )}

                    {/* Модальное окно для эндпоинта или сущности */}
                    {selectedItem && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={closeDetails}>
                            <div className="bg-gray-100 p-6 rounded-lg shadow-lg w-3/4 max-w-lg relative text-sm font-mono text-gray-700" onClick={(e) => e.stopPropagation()}>
                                {/* Кнопка для закрытия модального окна */}
                                <button className="absolute top-2 right-2 text-gray-400 hover:text-gray-600" onClick={closeDetails}>
                                    <CloseIcon />
                                </button>

                                {selectedItem.route ? (
                                    <>
                                        <h3 className="text-xl font-bold mb-4">Эндпоинт: {selectedItem.route}</h3>
                                        <p className="text-gray-700 mb-2"><strong>Методы:</strong> {selectedItem.methods.join(', ')}</p>
                                        <p className="text-gray-700 mb-2"><strong>Описание:</strong> {selectedItem.description}</p>

                                        <div className="mb-4">
                                            <strong>Входные параметры:</strong>
                                            <ul className="list-disc list-inside">
                                                {selectedItem.inputs.map((input, index) => (
                                                    <li key={index} className="text-gray-700">
                                                        {input.name} ({input.type}) {input.required ? '(Обязательный)' : '(Необязательный)'}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <div className="mb-4">
                                            <strong>Выходные параметры:</strong>
                                            <ul className="list-disc list-inside">
                                                {selectedItem.outputs.map((output, index) => (
                                                    <li key={index} className="text-gray-700">
                                                        {output.name} ({output.type})
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <h3 className="text-xl font-bold mb-4">Сущность: {selectedItem.name}</h3>
                                        <p className="text-gray-700 mb-2"><strong>Описание:</strong> {selectedItem.description}</p>

                                        <div className="mb-4">
                                            <strong>Поля сущности:</strong>
                                            <ul className="list-disc list-inside">
                                                {selectedItem.fields.map((field, index) => (
                                                    <li key={index} className="text-gray-700">
                                                        <span
                                                            className="font-bold text-gray-600 font-mono">{field.name}</span>
                                                        {field.nullable ? (
                                                            <span className="text-gray-600"></span>
                                                        ) : (
                                                            <span className="text-red-600">*</span>
                                                        )}
                                                        <span className="text-gray-600"> ({field.type})</span>
                                                        <span className="text-gray-500"> - {field.description}</span>
                                                    </li>
                                                ))}
                                                <span/>
                                                <span className="text-gray-500 p-1"> * - обязательное поле</span>
                                            </ul>
                                        </div>

                                        <div className="mb-4">
                                            <strong>Пример JSON:</strong>
                                            <pre className="bg-gray-900 text-gray-100 p-2 rounded">
                                                {JSON.stringify(generateEntityJsonExample(selectedItem), null, 2)}
                                            </pre>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="text-gray-500">Нет информации о компоненте</div>
            )}
        </div>
    );
};

export default InfoTab;
