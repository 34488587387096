import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Dialog, DialogContent } from '@mui/material';
import { add_tester } from '../services/apiService';  // Импортируем метод добавления тестера

const AlphaTestApplicationForm = ({ onRegisterSuccess, onClose }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState(''); // Дополнительная информация от пользователя
    const [hiddenField, setHiddenField] = useState(''); // Скрытое поле для защиты от ботов
    const [error, setError] = useState('');
    const [charCountError, setCharCountError] = useState(false); // Ошибка количества символов
    const [emailError, setEmailError] = useState(false); // Ошибка валидации email
    const [userIP, setUserIP] = useState(''); // IP-адрес пользователя

    // Функция валидации email
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Получаем IP-адрес пользователя при монтировании компонента
    useEffect(() => {
        const fetchUserIP = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setUserIP(data.ip); // Сохраняем IP в состояние
            } catch (error) {
                console.error('Ошибка при получении IP-адреса:', error);
            }
        };

        fetchUserIP();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Проверяем скрытое поле, оно должно быть пустым
        if (hiddenField !== '') {
            setError('Ошибка проверки на бота');
            return;
        }

        // Проверяем длину дополнительной информации
        if (additionalInfo.length > 400) {
            setCharCountError(true);
            return;
        }

        // Проверяем валидность e-mail
        if (!validateEmail(email)) {
            setEmailError(true);
            return;
        }

        try {
            // Добавляем IP в дополнительную информацию
            const fullAdditionalInfo = `${additionalInfo}\nIP пользователя: ${userIP}`;

            // Отправляем данные на сервер
            const data = await add_tester(username, email, fullAdditionalInfo);
            onRegisterSuccess(data);
        } catch (err) {
            setError('Ошибка при отправке заявки');
        }
    };

    return (
        <Dialog
            open={true}
            onClose={onClose} // Закрытие при нажатии вне окна
            PaperProps={{
                sx: {
                    backgroundColor: 'white',
                    boxShadow: 'none',
                    borderRadius: '12px',
                    padding: 0,
                },
            }}
            aria-labelledby="alpha-test-dialog"
            fullWidth
            maxWidth="sm"
        >
            <DialogContent sx={{ padding: 0 }}>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        mt: 3,
                        p: 4,
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        boxShadow: 'none',
                        maxWidth: '500px',
                        margin: 'auto',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Подача заявки на альфа-тестирование
                    </Typography>
                    {error && <Typography color="error" gutterBottom>{error}</Typography>}

                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        Все альфа-тестеры получают право на бесплатное использование всей текущей и будущей функциональности платформы
                    </Typography>

                    <TextField
                        label="Как вас называть"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Email"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError(false); // Сбрасываем ошибку при вводе
                        }}
                        required
                        error={emailError}
                        helperText={emailError ? 'Введите действительный e-mail' : ''}
                        sx={{ mb: 2 }}
                    />

                    <TextField
                        label="Дополнительная информация (до 400 символов)"
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                        value={additionalInfo}
                        onChange={(e) => {
                            setAdditionalInfo(e.target.value);
                            setCharCountError(false); // Сбрасываем ошибку при вводе
                        }}
                        error={charCountError}
                        helperText={charCountError ? 'Максимальная длина 400 символов' : ''}
                        placeholder="Расскажите о себе или предложите идеи"
                        sx={{ mb: 2 }}
                    />

                    {/* Скрытое поле для защиты от ботов */}
                    <TextField
                        type="text"
                        label="Не заполняйте это поле"
                        value={hiddenField}
                        onChange={(e) => setHiddenField(e.target.value)}
                        style={{ display: 'none' }}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: '#FF6F00',
                            color: 'white',
                            padding: '10px 0',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: '#E65100',
                            },
                        }}
                    >
                        Подать заявку
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default AlphaTestApplicationForm;
