import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getAssistantResponse } from '../../services/apiService';
import Lottie from 'react-lottie';
import catAnimation from '../../img/cat.json';
import loadingAnimation from '../../img/generator_loader.json';

const AIAssistant = ({ token, sandboxId, userId, language, selectedComponent, selectedTab, initialPrompt }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [conversation, setConversation] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const chatRef = useRef(null);
    const assistantRef = useRef(null);

    const handleAssistantResponse = useCallback(async (userInput) => {
        setIsLoading(true);
        setConversation(prev => [...prev, { type: 'user', content: userInput }]);
        try {
            const response = await getAssistantResponse(token, {
                user_input: userInput,
                sandbox_id: sandboxId,
                language: language,
                interface_state: {
                    selected_component: selectedComponent,
                    selected_tab: selectedTab
                },
                user_id: userId
            });
            setConversation(prev => [...prev, { type: 'assistant', content: response.response }]);
            setIsExpanded(true);
        } catch (error) {
            console.error('Error getting assistant response:', error);
            setConversation(prev => [...prev, { type: 'assistant', content: 'Извините, произошла ошибка при обработке вашего запроса.' }]);
            setIsExpanded(true);
        } finally {
            setIsLoading(false);
        }
    }, [token, sandboxId, language, selectedComponent, selectedTab, userId]);

    useEffect(() => {
        if (initialPrompt) {
            handleAssistantResponse(initialPrompt);
        }
    }, [initialPrompt, handleAssistantResponse]);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [conversation, isLoading]);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        if (input.trim()) {
            handleAssistantResponse(input);
            setInput('');
        }
    }, [input, handleAssistantResponse]);

    const catLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: catAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const loadingLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const handleWheel = useCallback((e) => {
        e.stopPropagation();
    }, []);

    return (
        <div className="fixed bottom-4 right-4 z-50" ref={assistantRef}>
            {!isExpanded && !isLoading ? (
                <button
                    onClick={() => setIsExpanded(true)}
                    className="w-20 h-20 bg-orange-500 rounded-full flex items-center justify-center shadow-lg hover:bg-orange-600 transition-colors"
                >
                    <Lottie options={catLottieOptions} height={80} width={80} />
                </button>
            ) : !isExpanded && isLoading ? (
                <div className="flex flex-col items-center">
                    <Lottie options={loadingLottieOptions} height={200} width={200} />
                    <span className="text-sm mt-2 text-orange-500">Готовим ответ</span>
                </div>
            ) : (
                <div
                    className="bg-white rounded-lg shadow-xl flex flex-col"
                    style={{width: '600px', height: '80vh', maxHeight: '800px'}}
                    onWheel={handleWheel}
                >
                    <div className="p-2 bg-orange-500 text-white rounded-t-lg flex justify-between items-center">
                        <h3 className="text-sm">AI Assistant</h3>
                        <button onClick={() => setIsExpanded(false)} className="text-xl">&times;</button>
                    </div>
                    <div
                        ref={chatRef}
                        className="flex-grow overflow-y-auto p-4 space-y-4 text-sm"
                        style={{
                            height: 'calc(100% - 120px)',
                            overflowX: 'hidden',
                            fontFamily: "'Source Code Pro', monospace",
                            backgroundColor: '#f8f8f8'
                        }}
                    >
                        {conversation.map((message, index) => (
                            <div key={index} className={`${message.type === 'user' ? 'text-right' : 'text-left'}`}>
                                <div
                                    className={`inline-block p-2 rounded-lg ${
                                        message.type === 'user'
                                            ? 'bg-orange-100 text-right'
                                            : 'bg-white border border-gray-200 text-left'
                                    }`}
                                    style={{
                                        maxWidth: '90%',
                                        overflowWrap: 'break-word',
                                        marginLeft: message.type === 'user' ? 'auto' : '0',
                                        marginRight: message.type === 'user' ? '0' : 'auto'
                                    }}
                                >
                                    {message.type === 'assistant' ? (
                                        <ReactMarkdown
                                            className="prose max-w-none text-sm"
                                            components={{
                                                code({node, inline, className, children, ...props}) {
                                                    const match = /language-(\w+)/.exec(className || '')
                                                    return !inline && match ? (
                                                        <SyntaxHighlighter
                                                            style={vscDarkPlus}
                                                            language={match[1]}
                                                            PreTag="div"
                                                            {...props}
                                                        >
                                                            {String(children).replace(/\n$/, '')}
                                                        </SyntaxHighlighter>
                                                    ) : (
                                                        <code className={className} {...props}>
                                                            {children}
                                                        </code>
                                                    )
                                                }
                                            }}
                                        >
                                            {message.content}
                                        </ReactMarkdown>
                                    ) : (
                                        <p className="text-sm">{message.content}</p>
                                    )}
                                </div>
                            </div>
                        ))}
                        {isLoading && (
                            <div className="text-left">
                                <div className="inline-block p-2 rounded-lg bg-gray-100">
                                    Ассистент печатает...
                                </div>
                            </div>
                        )}
                    </div>
                    <form onSubmit={handleSubmit} className="p-4 border-t bg-gray-50">
                        <div className="flex flex-col space-y-2">
                            <textarea
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Задай свой вопрос здесь..."
                                className="flex-grow p-2 border rounded-lg resize-none text-sm"
                                rows="3"
                                style={{fontFamily: "'Source Code Pro', monospace"}}
                            />
                            <button type="submit"
                                    className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors text-sm">
                                Отправить
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default AIAssistant;