import React, { useCallback, useMemo } from 'react';
import { Group, Text, Circle, Image } from 'react-konva';
import useImage from 'use-image';

// Размеры иконок
const iconWidth = 50;
const iconHeight = 50;

// Функция для получения пути к иконке на основе типа компонента
const getImageForComponent = (typeName) => {
    switch (typeName) {
        case 'Microservice':
            return '/icons/microservice.png';
        case 'Database':
            return '/icons/database.png';
        case 'Gateway':
            return '/icons/gateway.png';
        case 'Message broker':
            return '/icons/message-broker.png';
        case 'Backend service':
            return '/icons/backend-service.png';
        case 'Web client':
            return '/icons/web-client.png';
        case 'Mobile application':
            return '/icons/mobile-app.png';
        case 'Desktop application':
            return '/icons/desktop-app.png';
        case 'Frontend service':
            return '/icons/frontend-service.png';
        case 'CRM system':
            return '/icons/crm-system.png';
        case 'ERP system':
            return '/icons/erp-system.png';
        case 'Active Directory':
            return '/icons/active-directory.png';
        case 'Corporate system':
            return '/icons/corporate-system.png';
        case 'Mail server':
            return '/icons/mail-server.png';
        case 'Cloud service':
            return '/icons/cloud-service.png';
        case 'Enterprise bus':
            return '/icons/enterprise-bus.png';
        case 'FTP server':
            return '/icons/ftp-server.png';
        case 'User':
            return '/icons/user.png';
        case 'Physical server':
            return '/icons/physical-server.png';
        case 'Cloud':
            return '/icons/cloud.png';
        default:
            return '/icons/default.png';
    }
};

const StatusIndicator = ({ status }) => {
    const getColor = () => {
        switch (status) {
            case 'creating':
                return 'orange';
            case 'active':
                return 'green';
            default:
                return 'transparent';
        }
    };

    return (
        <Circle
            x={iconWidth - 2}
            y={2}
            radius={5}
            fill={getColor()}
        />
    );
};

const ComponentRenderer = React.memo(({ comp, onContextMenu, onClick, onDragMove }) => {
    const [image] = useImage(getImageForComponent(comp.type_name));

    const handleDragEnd = useCallback((e) => {
        onDragMove(e, comp.id);
        e.target.opacity(1);
    }, [comp.id, onDragMove]);

    const handleContextMenu = useCallback((e) => {
        e.evt.preventDefault();
        onContextMenu(e, comp);
    }, [comp, onContextMenu]);

    const handleClick = useCallback(() => {
        onClick(comp);
    }, [comp, onClick]);

    const handleDragStart = useCallback((e) => {
        e.target.opacity(0.7);
    }, []);

    const handleMouseEnter = useCallback((e) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'pointer';
        e.target.to({
            scaleX: 1.05,
            scaleY: 1.05,
            duration: 0.2,
        });
    }, []);

    const handleMouseLeave = useCallback((e) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'default';
        e.target.to({
            scaleX: 1,
            scaleY: 1,
            duration: 0.2,
        });
    }, []);

    const groupProps = useMemo(() => ({
        x: comp.x,
        y: comp.y,
        draggable: true,
        onDragEnd: handleDragEnd,
        onContextMenu: handleContextMenu,
        onClick: handleClick,
        onDragStart: handleDragStart,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
    }), [comp.x, comp.y, handleDragEnd, handleContextMenu, handleClick, handleDragStart, handleMouseEnter, handleMouseLeave]);

    return (
        <Group {...groupProps}>
            <Image
                image={image}
                width={iconWidth}
                height={iconHeight}
            />
            <Text
                text={comp.name}
                x={iconWidth / 2}
                y={iconHeight + 5}
                fontSize={14}
                fontFamily="Sans"
                fill="black"
                width={100}
                align="center"
                offsetX={50}
            />
            {comp.category_name === 'backend' && (
                <StatusIndicator status={comp.status} />
            )}
        </Group>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.comp.id === nextProps.comp.id &&
        prevProps.comp.x === nextProps.comp.x &&
        prevProps.comp.y === nextProps.comp.y &&
        prevProps.comp.name === nextProps.comp.name &&
        prevProps.comp.status === nextProps.comp.status
    );
});

ComponentRenderer.displayName = 'ComponentRenderer';

export default ComponentRenderer;
