import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, TextField } from '@mui/material';
import { motion } from 'framer-motion';
import { fetchSandboxes, startCamundaProcess } from '../services/apiService';
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import GenerationLoader from './GenerationLoader';

const Dashboard = ({ token, userInfo }) => {
  const [mySandboxes, setMySandboxes] = useState([]);
  const [accessibleSandboxes, setAccessibleSandboxes] = useState([]);
  const [open, setOpen] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [ProcessId, setProcessId] = useState(null);
  const [SandboxId, setSandboxId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadSandboxes = async () => {
      if (userInfo && userInfo.id && token) {
        const sandboxes = await fetchSandboxes(token, userInfo.id);
        const createdByMe = sandboxes.filter(sandbox => sandbox.owner === userInfo.id);
        const accessible = sandboxes.filter(sandbox => sandbox.coworkers.includes(userInfo.id));

        setMySandboxes(createdByMe);
        setAccessibleSandboxes(accessible);
      }
    };

    loadSandboxes();
  }, [userInfo, token]);

  if (!userInfo) {
    return <p>Загрузка...</p>;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartGeneration = async () => {
    try {
      const data = await startCamundaProcess(token, prompt, userInfo.id, false);
      setProcessId(data.process_id);
      setSandboxId(data.sandbox_id)
      setIsLoading(true);
    } catch (error) {
      console.error('Ошибка при запуске процесса:', error);
    }

    setOpen(false);
  };

  // Мягкая анимация для карточек
  const cardVariants = {
    hover: {
      scale: 1.02,
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      transition: { duration: 0.2 },
    },
  };

  // Если идет процесс генерации, показываем лоадер
  if (isLoading && ProcessId && SandboxId) {
    return <GenerationLoader token={token} ProcessId={ProcessId} SandboxId={SandboxId} />;
  }

  return (
      <div className="container mx-auto py-16 px-4 max-w-screen-lg">
        {/* Плитка профиля */}
        <motion.div
            className="bg-white rounded-lg shadow-lg p-8 mb-12 flex justify-between items-center border border-gray-100 hover:border-gray-200"
            whileHover="hover"
            variants={cardVariants}
        >
          <div className="flex items-center">
            <Avatar
                className="bg-[#FF6F00] mr-4"
                sx={{ width: 64, height: 64 }}
            >
              {userInfo.username.charAt(0).toUpperCase()}
            </Avatar>
            <div>
              <h2 className="text-2xl font-bold">{userInfo.username}</h2>
              <p className="text-gray-600">{userInfo.email}</p>
            </div>
          </div>
          <div className="text-right">
            {/* <Link to="/profile" className="text-blue-500 hover:underline">
              Перейти в профиль
            </Link> */}
          </div>
        </motion.div>

        {/* Плитки с курсами и песочницами */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12">
          <motion.div
              className="bg-white rounded-lg shadow-lg p-8 border border-gray-100 hover:border-gray-200"
              whileHover="hover"
              variants={cardVariants}
          >
            <h3 className="text-xl font-bold mb-4">Мои курсы</h3>
            <p className="text-gray-600">Краткая информация о текущих и пройденных курсах.</p>
          </motion.div>

          {/* Плитка "Мои песочницы" */}
          <motion.div
              className="bg-white rounded-lg shadow-lg p-8 border border-gray-100 hover:border-gray-200"
              whileHover="hover"
              variants={cardVariants}
          >
            <h3 className="text-xl font-bold mb-4">Мои песочницы</h3>
            <div className="text-gray-600">
              <div className="mb-4">
                <h4 className="font-bold">Созданные мной</h4>
                {mySandboxes.length > 0 ? (
                    <ul>
                      {mySandboxes.map(sandbox => (
                          <li key={sandbox.id} className="text-primary underline">
                            <Link to={`/sandbox/${sandbox.id}`}>{sandbox.name}</Link>
                          </li>
                      ))}
                    </ul>
                ) : (
                    <p>У вас нет созданных песочниц.</p>
                )}
              </div>
              <div>
                <h4 className="font-bold">Доступные мне</h4>
                {accessibleSandboxes.length > 0 ? (
                    <ul>
                      {accessibleSandboxes.map(sandbox => (
                          <li key={sandbox.id} className="text-primary underline">
                            <Link to={`/sandbox/${sandbox.id}`}>{sandbox.name}</Link>
                          </li>
                      ))}
                    </ul>
                ) : (
                    <p>У вас нет доступных песочниц.</p>
                )}
              </div>
            </div>
            <button
                className="mt-4 bg-orange-500 text-white py-3 px-6 rounded-lg hover:bg-orange-600 transition duration-200"
                onClick={handleClickOpen}
            >
              Создать
            </button>
            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <h2 className="text-lg font-bold mb-4">Введите промпт для генерации</h2>
                <TextField
                    multiline
                    rows={5}
                    fullWidth
                    variant="outlined"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    placeholder="Например: 'система по учёту ёжиков в лесу' или 'система с микросервисной архитектурой и брокером сообщений для взаимодействия с CRM' "
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleStartGeneration}
                    className="mt-4"
                    style={{ marginTop: '20px' }}
                >
                  Начать генерацию
                </Button>
              </DialogContent>
            </Dialog>
          </motion.div>
        </div>

        {/* Плитка с подписками */}
        <motion.div
            className="bg-white rounded-lg shadow-lg p-8 border border-gray-100 hover:border-gray-200"
            whileHover="hover"
            variants={cardVariants}
        >
          <h3 className="text-xl font-bold mb-4">Подписки</h3>
          <p className="text-gray-600">Базовая подписка</p>
          <button className="mt-4 bg-orange-500 text-white py-3 px-6 rounded-lg hover:bg-orange-600 transition duration-200">
            Улучшить
          </button>
        </motion.div>
      </div>
  );
};

export default Dashboard;
