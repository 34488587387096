import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, IconButton, Avatar, Typography, Button, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import TranslateIcon from '@mui/icons-material/Translate';
import i18n from '../i18n';

const Header = ({ userInfo, onLogout, onLoginClick, onRegisterClick }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (profileMenuAnchor) {
      setIsProfileMenuOpen(true);
    } else {
      setIsProfileMenuOpen(false);
    }
  }, [profileMenuAnchor]);

  useEffect(() => {
    if (anchorEl) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [anchorEl]);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const handleProfileMenuOpen = (event) => setProfileMenuAnchor(event.currentTarget);
  const handleProfileMenuClose = () => setProfileMenuAnchor(null);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleCloseMenu();
  };
  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  return (
    <header className="bg-black bg-opacity-90 text-white py-4 fixed top-0 left-0 w-full z-10 backdrop-blur-lg">
      <div className="container mx-auto flex justify-between items-center">
        {/* Левый блок для логотипа */}
        <div className="flex items-center">
          <Link to="/" className="text-xl font-semibold">
            Dvakota
          </Link>
        </div>

        {/* Меню для мобильных устройств */}
        <div className="md:hidden">
          <IconButton onClick={toggleDrawer(true)} className="text-white">
            <MenuIcon className="text-white" /> {/* Убедимся, что цвет иконки белый */}
          </IconButton>
          <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} PaperProps={{ style: { backgroundColor: '#FF6F00', color: 'white' } }}>
            <List>
              <ListItem button component={Link} to="/" onClick={toggleDrawer(false)}>
                <ListItemText primary={t('header.home')} />
              </ListItem>
              {userInfo ? (
                <>
                  <ListItem button component={Link} to="/courses" onClick={toggleDrawer(false)}>
                    <ListItemText primary={t('header.courses')} />
                  </ListItem>
                  <ListItem button component={Link} to="/sandbox" onClick={toggleDrawer(false)}>
                    <ListItemText primary={t('header.sandbox')} />
                  </ListItem>
                  <ListItem button onClick={onLogout}>
                    <ListItemText primary={t('header.logout')} />
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem button component={Link} to="/demo" onClick={toggleDrawer(false)}>
                    <ListItemText primary={t('header.demo')} />
                  </ListItem>
                  <ListItem button component={Link} to="/about" onClick={toggleDrawer(false)}>
                    <ListItemText primary={t('header.about')} />
                  </ListItem>
                  <ListItem button onClick={onLoginClick}>
                    <ListItemText primary={t('header.login')} />
                  </ListItem>
                  <ListItem button onClick={onRegisterClick}>
                    <ListItemText primary={t('header.sign_up')} />
                  </ListItem>
                </>
              )}
            </List>
          </Drawer>
        </div>

        {/* Правый блок для навигации на больших экранах */}
        <div className="hidden md:flex items-center space-x-6">
          <Link to="/" className="text-white hover:text-gray-300">{t('header.home')}</Link>
          {userInfo ? (
            <>
              <Link to="/courses" className="text-white hover:text-gray-300">{t('header.courses')}</Link>
              <Link to="/sandbox" className="text-white hover:text-gray-300">{t('header.sandbox')}</Link>
            </>
          ) : (
            <>
              <Link to="/demo" className="text-white hover:text-gray-300">{t('header.demo')}</Link>
              <Link to="/about" className="text-white hover:text-gray-300">{t('header.about')}</Link>
            </>
          )}

          {userInfo ? (
            <>
              <div
                className="flex items-center cursor-pointer"
                onClick={handleProfileMenuOpen}
              >
                <Avatar className="bg-[#FF6F00] mr-2">
                  {userInfo.username.charAt(0).toUpperCase()}
                </Avatar>
                <Typography className="text-white">{userInfo.username}</Typography>
              </div>
              <Menu
                anchorEl={profileMenuAnchor}
                open={isProfileMenuOpen}
                onClose={handleProfileMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <MenuItem onClick={onLogout}>{t('header.logout')}</MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <button onClick={onLoginClick} className="text-white hover:text-gray-300">{t('header.login')}</button>
              <Button
                onClick={onRegisterClick}
                style={{
                  backgroundColor: '#FF6F00',
                  color: 'white',
                  padding: '8px 16px',
                  borderRadius: '9999px', // equivalent to Tailwind's rounded-full
                  textTransform: 'none', // disable uppercase transformation
                }}
                className="hover:bg-[#E65100]"
              >
                {t('header.sign_up')}
              </Button>
            </>
          )}
          <IconButton onClick={handleMenu}>
            <TranslateIcon className="text-white" /> {/* Убедимся, что цвет иконки белый */}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            <MenuItem onClick={() => changeLanguage('ru')}>Русский</MenuItem>
            <MenuItem onClick={() => changeLanguage('pt')}>Português</MenuItem>
            <MenuItem onClick={() => changeLanguage('sr')}>Српски</MenuItem>
            <MenuItem onClick={() => changeLanguage('hr')}>Hrvatski</MenuItem>
            <MenuItem onClick={() => changeLanguage('bs')}>Bosanski</MenuItem>
            <MenuItem onClick={() => changeLanguage('me')}>Crnogorski</MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;
