import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Container, Grid, Typography, Box, CircularProgress, Pagination } from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CourseCard from '../components/courses/CourseCard';
import SearchBar from '../components/courses/SearchBar';
import CourseFilters from '../components/courses/CourseFilters';
import { AuthContext } from '../App';
import { fetchCourses, fetchRecommendedCourses, fetchCourseCategories } from '../services/apiService';

const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [recommendedCourses, setRecommendedCourses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { isAuthenticated, token } = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [priceFilter, setPriceFilter] = useState('all');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 12; // Количество курсов на странице

    const loadCourses = useCallback(async () => {
        setIsLoading(true);
        try {
            const params = {
                search: searchTerm,
                category: selectedCategory,
                page: page,
                pageSize: pageSize
            };
            if (priceFilter === 'free') params.maxPrice = 0;
            if (priceFilter === 'paid') params.minPrice = 0.01;

            const { results, count } = await fetchCourses(params);
            setCourses(results);
            setTotalPages(Math.ceil(count / pageSize));
        } catch (error) {
            console.error("Error loading courses:", error);
        } finally {
            setIsLoading(false);
        }
    }, [searchTerm, selectedCategory, priceFilter, page]);

    const loadInitialData = useCallback(async () => {
        setIsLoading(true);
        try {
            const [fetchedCategories, recommendedCoursesData] = await Promise.all([
                fetchCourseCategories(),
                isAuthenticated ? fetchRecommendedCourses(token) : []
            ]);
            setCategories(fetchedCategories);
            setRecommendedCourses(recommendedCoursesData);
        } catch (error) {
            console.error("Error loading initial data:", error);
        } finally {
            setIsLoading(false);
        }
    }, [isAuthenticated, token]);

    useEffect(() => {
        loadInitialData();
    }, [loadInitialData]);

    useEffect(() => {
        loadCourses();
    }, [loadCourses]);

    const handleSearch = (term) => {
        setSearchTerm(term);
        setPage(1);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setPage(1);
    };

    const handlePriceFilterChange = (event) => {
        setPriceFilter(event.target.value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    if (isLoading) {
        return (
            <Container maxWidth="xl" sx={{ py: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="xl" sx={{ py: 4 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 3 }}>
                    <SearchBar onSearch={handleSearch} value={searchTerm} onChange={setSearchTerm} />
                </Box>
                <CourseFilters
                    categories={categories}
                    selectedCategory={selectedCategory}
                    onCategoryChange={handleCategoryChange}
                    priceFilter={priceFilter}
                    onPriceFilterChange={handlePriceFilterChange}
                />
            </Box>


            {courses.length > 0 ? (
                <>
                    <Grid container spacing={4}>
                        {courses.map((course) => (
                            <Grid item xs={12} sm={6} md={3} key={course.id}>
                                <CourseCard course={course} />
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </>
            ) : (
                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                    Курсы не найдены. Попробуйте изменить параметры поиска или фильтрации.
                </Typography>
            )}

            {isAuthenticated && recommendedCourses.length > 0 && (
                <Box sx={{ mt: 8 }}>
                    <Typography variant="h5" component="h2" sx={{ mb: 4, textAlign: 'center' }}>
                        Рекомендованные курсы
                    </Typography>
                    <Grid container spacing={4}>
                        {recommendedCourses.map((course) => (
                            <Grid item xs={12} sm={6} md={3} key={course.id}>
                                <CourseCard course={course} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Container>
    );
};

export default Courses;