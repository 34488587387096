import React from 'react';
import {
    Box,
    Typography,
    Button,
    Paper,
    Divider,
    alpha
} from '@mui/material';
import {
    EmojiEvents as EmojiEventsIcon,
    CheckCircle as CheckCircleIcon,
    Code as CodeIcon,
    Assignment as AssignmentIcon
} from '@mui/icons-material';
import SandboxAssignment from '../sandbox/SandboxAssignment';

const AssignmentCard = ({ assignment, token, onSubmit, onOpenSandbox, deploying }) => {
    const isAssignmentCard = assignment.assignment_type !== 'sandbox';

    return (
        <Paper
            elevation={0}
            sx={{
                p: 3,
                borderRadius: 2,
                bgcolor: assignment.completed ? alpha('#4CAF50', 0.1) : alpha('#FF5722', 0.05),
                position: 'relative',
                overflow: 'hidden',
                transition: 'all 0.2s ease-in-out',
                border: '1px solid',
                borderColor: assignment.completed ? alpha('#4CAF50', 0.2) : alpha('#FF5722', 0.1),
                '&:hover': {
                    bgcolor: assignment.completed ? alpha('#4CAF50', 0.15) : alpha('#FF5722', 0.1),
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
                }
            }}
        >
            {assignment.completed && (
                <CheckCircleIcon
                    sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        color: 'success.main'
                    }}
                />
            )}

            {isAssignmentCard ? (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Box sx={{ flex: 1, pr: 4 }}>
                            <Typography variant="h6" sx={{ fontWeight: 500, mb: 1 }}>
                                {assignment.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mb: 2 }}
                            >
                                {assignment.description}
                            </Typography>
                        </Box>
                        {assignment.points && (
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5,
                                color: 'warning.dark',
                                bgcolor: alpha('d2d1d1', 0.1),
                                px: 2,
                                py: 0.5,
                                borderRadius: 2,
                                height: 'fit-content',
                                border: '1px solid',
                                borderColor: alpha('#7a7877', 0.2)
                            }}>
                                <EmojiEventsIcon sx={{ fontSize: 20 }} />
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    {assignment.points} баллов
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Button
                        variant="contained"
                        onClick={() => onSubmit(assignment)}
                        disabled={assignment.completed}
                        sx={{
                            bgcolor: 'warning.main',
                            '&:hover': {
                                bgcolor: 'warning.dark'
                            }
                        }}
                    >
                        {assignment.completed ? 'Выполнено' : 'Отправить решение'}
                    </Button>
                </>
            ) : (
                <SandboxAssignment
                    assignment={assignment}
                    token={token}
                    onOpenSandbox={onOpenSandbox}
                    deploying={deploying}
                    hideContainer // Добавляем этот флаг, чтобы избежать дублирования контейнера
                />
            )}
        </Paper>
    );
};

const AssignmentSection = ({ title, icon: Icon, assignments, token, onSubmit, onOpenSandbox, deployingEnvironments }) => (
    assignments.length > 0 && (
        <Box sx={{ mb: 4 }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 2,
                px: 1
            }}>
                <Icon sx={{ color: 'warning.main' }} />
                <Typography variant="h6" sx={{
                    fontWeight: 500,
                    color: 'black'
                }}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{ '& > *:not(:last-child)': { mb: 2 } }}>
                {assignments.map((assignment) => (
                    <AssignmentCard
                        key={assignment.id}
                        assignment={assignment}
                        token={token}
                        onSubmit={onSubmit}
                        onOpenSandbox={onOpenSandbox}
                        deploying={deployingEnvironments?.[assignment.id]}
                    />
                ))}
            </Box>
        </Box>
    )
);

const AssignmentList = ({
                            assignments,
                            token,
                            onSubmit,
                            onOpenSandbox,
                            deployingEnvironments
                        }) => {
    if (!assignments) {
        return <Typography>Загрузка...</Typography>;
    }

    if (assignments.length === 0) {
        return <Typography>Нет доступных заданий</Typography>;
    }

    const sandboxAssignments = assignments.filter(a => a.assignment_type === 'sandbox');
    const regularAssignments = assignments.filter(a => a.assignment_type !== 'sandbox');

    return (
        <Paper
            elevation={0}
            sx={{
                borderRadius: 2,
                p: 4,
                bgcolor: 'transparent'
            }}
        >
            <Typography variant="h5" sx={{
                fontWeight: 'bold',
                mb: 4,
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                px: 1
            }}>
                <AssignmentIcon sx={{ color: 'warning.main' }} />
                Задания
            </Typography>

            <AssignmentSection
                title="Практические задания"
                icon={CodeIcon}
                assignments={sandboxAssignments}
                token={token}
                onSubmit={onSubmit}
                onOpenSandbox={onOpenSandbox}
                deployingEnvironments={deployingEnvironments}
            />

            {sandboxAssignments.length > 0 && regularAssignments.length > 0 && (
                <Divider sx={{ my: 4 }} />
            )}

            <AssignmentSection
                title="Теоретические задания"
                icon={AssignmentIcon}
                assignments={regularAssignments}
                token={token}
                onSubmit={onSubmit}
                onOpenSandbox={onOpenSandbox}
                deployingEnvironments={deployingEnvironments}
            />
        </Paper>
    );
};

export default AssignmentList;